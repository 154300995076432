import styled, { css } from "styled-components/macro";

interface IProps {
  mine: boolean;
}

const StandingsRow = styled.tr<IProps>`
  ${(props) =>
    props.mine &&
    css`
      td {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        font-weight: normal;
        > a {
          color: ${({ theme }) => theme.colors.white};
          font-weight: bold;
        }
      }
    `}
`;
export default StandingsRow;
