import styled, { css } from "styled-components/macro";

const ScorePrefixStyles = css`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: 3.2rem;
  text-align: center;
  vertical-align: middle;
`;

export const WinPrefix = styled.span`
  ${ScorePrefixStyles}
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.yellow};
`;

export const LosePrefix = styled.span`
  ${ScorePrefixStyles}
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border: ${({ theme }) => theme.borders[0]};
`;

export const DrawPrefix = styled.span`
  ${ScorePrefixStyles}
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.grey}; ;
`;
