import { Link } from "@reach/router";
import React from "react";
import styled, { css } from "styled-components/macro";

const NavList = styled.ul`
  display: flex;
  margin: ${({ theme }) => theme.space[2]};
  padding-left: 0;
  border: ${({ theme }) => theme.borders[0]};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  list-style-type: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-right: 20%;
    margin-left: 20%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    margin-right: 30%;
    margin-left: 30%;
  }
`;

const NavItem = styled.li`
  flex: 1;
  :not(:last-child) {
    border-right: 2px solid white;
  }
`;

// TODO - This stops isCurrent being passed to Link but doesn't have
// any type checking
const NavLink = styled(({ isCurrent, ...rest }) => <Link {...rest} />)`
  display: block;
  margin: 2px;
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[0]};
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  text-decoration: none;
  text-align: center;

  ${(props) =>
    props.isCurrent &&
    css`
      background-color: ${({ theme }) => theme.colors.yellow};
      color: ${({ theme }) => theme.colors.black};
    `}
`;

interface ILinks {
  href: string;
  text: string;
}

interface ISubNav {
  links: ILinks[];
}

const SubNav: React.FC<ISubNav> = ({ links }) => (
  <nav>
    <NavList>
      {links.map((l) => (
        <NavItem key={l.href}>
          <NavLink to={l.href} isCurrent={window.location.pathname === l.href}>
            {l.text}
          </NavLink>
        </NavItem>
      ))}
    </NavList>
  </nav>
);

export default SubNav;
