import { hiDPI, size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as GBFlag } from "../img/flags/gb.svg";
import { ReactComponent as SEFlag } from "../img/flags/se.svg";
import headerAsset1x from "../img/header-asset@1x.png";
import headerAsset2x from "../img/header-asset@2x.png";
import logoFantasy1x from "../img/logo-fantasy@1x.png";
import logoFantasy1xWebp from "../img/logo-fantasy@1x.webp";
import logoFantasy2x from "../img/logo-fantasy@2x.png";
import logoFantasy2xWebp from "../img/logo-fantasy@2x.webp";
import Navigation from "./nav/Navigation";

const LogoFlagWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLink = styled.a`
  display: inline-block;
  margin: 3rem 0;
  vertical-align: middle;
`;

const GameHeaderOuter = styled.div`
  background-color: ${(props) => props.theme.colors.blueDarker3};
  background-repeat: no-repeat;
  background-position: 220px;
  background-size: cover;
  background-image: url(${headerAsset1x});

  ${hiDPI(2)} {
    background-image: url(${headerAsset2x});
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    background-position: right;
    background-size: contain;
  }
`;

const Header = styled.div`
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;
`;

const FlagList = styled.ul`
  width: 34px;
  padding: 2px 2px 1px;
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.white};
  list-style-type: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: auto;
    padding: 6px 4px 4px;
  }
`;

const FlagItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  padding: ${({ theme }) => theme.space[1]};
  line-height: 1;

  :last-child {
    padding-bottom: 3px;
  }

  :not(:last-child) {
    border-bottom: ${({ theme }) => theme.borders[0]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    :last-child {
      padding-bottom: 0;
    }

    :not(:last-child) {
      border-bottom: ${({ theme }) => theme.borders[0]};
    }

    :not(:last-child) {
      border-bottom: 0;
      border-right: ${({ theme }) => theme.borders[0]};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      padding: 0 ${({ theme }) => theme.space[2]};
    }
  }
`;

const FlagLink = styled.a`
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
`;

const StyledNOFlag = styled(SEFlag)`
  ${size(14, 20)}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(22, 30)}
  }
`;

const StyledGBFlag = styled(GBFlag)`
  ${size(14, 20)}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(22, 30)}
  }
`;

const GameHeader = () => {
  return (
    <GameHeaderOuter>
      <Header>
        <LogoFlagWrap>
          <HeaderLink
            href="https://allsvenskan.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture>
              <source
                type="image/webp"
                srcSet={`${logoFantasy1xWebp}, ${logoFantasy2xWebp} 2x`}
              />
              <img
                srcSet={`${logoFantasy1x}, ${logoFantasy2x} 2x`}
                src={logoFantasy1x}
                alt="Allsvenskan Fantasy"
              />
            </picture>
          </HeaderLink>
          <FlagList>
            <FlagItem>
              <FlagLink href="https://fantasy.allsvenskan.se">
                <StyledNOFlag />
              </FlagLink>
            </FlagItem>
            <FlagItem>
              <FlagLink href="https://en.fantasy.allsvenskan.se">
                <StyledGBFlag />
              </FlagLink>
            </FlagItem>
          </FlagList>
        </LogoFlagWrap>
        <Navigation />
      </Header>
    </GameHeaderOuter>
  );
};

export default GameHeader;
