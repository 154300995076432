import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Parser from "rss-parser";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getURL } from "../../core/store/urls/reducers";
import { fetchURL } from "../../core/store/urls/thunks";
import { ControlArrowRight } from "../icons/Arrows";
import LogoBar from "../LogoBar";
import filterOnSSL from "./filterOnSSL";

const parser = new Parser();

const StyledProvider = styled.section`
  border: 1px solid #e5e5e5;
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const ProviderHeader = styled.header``;

const ProviderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProviderFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

const StyledLink = styled.a`
  color: #000;
  text-decoration: none;
`;

const Article = styled.article`
  width: 50%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 25%;
  }
`;

const ArticleFeatureImg = styled.img`
  max-width: 100%;
`;

const StyledMoreLink = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const SubHeading = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 2rem;
  }
`;

interface IProps {
  link: string;
  url: string;
}

const ScoutProvider: React.FC<IProps> = ({ link, url }) => {
  const { t } = useTranslation();
  const [providerData, setProviderData] = useState<Parser.Item[] | null>(null);

  // Fetch RSS
  const reduxDispatch = useDispatch<ThunkDispatch>();
  useEffect(() => {
    reduxDispatch(fetchURL(url));
  }, [reduxDispatch, url]);

  // Get raw RSS content, will be null until we have some
  const content = useSelector((state: RootState) => getURL(state, url));

  // Parse RSS data
  useEffect(() => {
    const parseContent = async () => {
      try {
        const feed = await parser.parseString(content.text);
        if (feed && feed.items) {
          const filteredSslFeedItems = filterOnSSL(feed.items).splice(0, 4); // Only show 4 news articles

          setProviderData(filteredSslFeedItems);
        }
      } catch (e) {}
    };
    if (content) {
      parseContent();
    }
  }, [content]);

  if (!providerData) {
    return null;
  }

  return (
    <StyledProvider style={{ marginBottom: 20 }}>
      <ProviderHeader>
        <LogoBar>
          <SubHeading>{t("scout.heading", "Allsvenskan Fantasy")}</SubHeading>
        </LogoBar>
      </ProviderHeader>
      <ProviderContent>
        {providerData.map((item) => {
          return (
            <Article key={item.title}>
              <StyledLink
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.enclosure && (
                  <ArticleFeatureImg
                    src={item.enclosure.url}
                    alt="Article fea"
                  />
                )}
                <h4>{item.title}</h4>
              </StyledLink>
            </Article>
          );
        })}
      </ProviderContent>
      <ProviderFooter>
        <StyledMoreLink href={link} target="_blank" rel="noopener noreferrer">
          {t("scout.more", "More")}
          <ControlArrowRight />
        </StyledMoreLink>
      </ProviderFooter>
    </StyledProvider>
  );
};

export default ScoutProvider;
