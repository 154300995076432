import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { getEvents } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getCreateClassicLeagueError } from "../../core/store/leagues/reducers";
import { createClassicLeague } from "../../core/store/leagues/thunks";
import { ICreateClassicLeagueData } from "../../core/store/leagues/types";
import Button from "../Button";
import Copy from "../Copy";
import { FieldWrap, InputField, SelectField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";
import SubHeading from "../SubHeading";
import Collapsible from "../Collapsible";

interface IPropsFromState {
  error: IError | null;
  events: IEvent[];
  settings: ISettings | null;
}

interface IPropsFromDispatch {
  createClassicLeague: (data: ICreateClassicLeagueData) => void;
}

type Props = WithTranslation & IPropsFromState & IPropsFromDispatch;

interface IState {
  name: string;
  startEvent: number;
}

class CreateClassic extends React.Component<Props, IState> {
  public state: IState = {
    name: "",
    startEvent: 1,
  };

  public requiredFields: Array<keyof IState> = ["name", "startEvent"];

  public apiDataFromState = (): ICreateClassicLeagueData => ({
    name: this.state.name,
    start_event: this.state.startEvent,
  });

  public handleNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ name: e.currentTarget.value });

  public handleStartEventChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ startEvent: parseInt(e.currentTarget.value, 10) });

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.createClassicLeague(this.apiDataFromState());
  };

  public render() {
    const { error, events, settings, t } = this.props;
    const disabled = this.requiredFields.some((e) => !this.state[e]);
    return (
      <Wrapper>
        <Main>
          <Copy>
            <Title>
              {t("createClassic.title", "Create a new classic league & cup")}
            </Title>
            {error ? (
              error.badRequest &&
              error.badRequest.non_field_errors &&
              error.badRequest.non_field_errors[0].code ===
                "max_private_entries" ? (
                <p>
                  {t(
                    "createClassic.error",
                    "You may only enter {{ maxPrivateEntries }} private leagues",
                    {
                      maxPrivateEntries:
                        settings && settings.league_join_private_max,
                    }
                  )}
                </p>
              ) : (
                <p>{JSON.stringify(error)}</p>
              )
            ) : (
              ""
            )}
            <form onSubmit={this.handleSubmit}>
              <FieldWrap>
                <InputField
                  id="ismLeagueName"
                  label={t("createClassic.leagueName.label", "League name")}
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  hint={t(
                    "createClassic.leagueName.hint",
                    "Maximum 30 characters"
                  )}
                  maxLength={30}
                />
              </FieldWrap>
              <FieldWrap>
                <SelectField
                  id="ismLeagueStartEvent"
                  value={this.state.startEvent}
                  onChange={this.handleStartEventChange}
                  label={t("createClassic.startEventLabel", "Scoring starts")}
                >
                  {events.map((e) => (
                    <option
                      value={e.id}
                      aria-selected={e.id === this.state.startEvent}
                      key={e.id}
                    >
                      {e.name}
                    </option>
                  ))}
                </SelectField>
              </FieldWrap>
              <FieldWrap>
                <Button type="submit" disabled={disabled}>
                  {t("createClassic.button", "Create league")}
                </Button>
              </FieldWrap>
              <SubHeading>{t("createClassic.theCup", "The Cup")}</SubHeading>
              <p>
                {t(
                  "createClassic.theCupExplain",
                  "League cups are now included in Private Classic Leagues. The League Cups will start in the second half of the season dependent on the size of your league and will conclude in Round 30."
                )}
              </p>
              <Collapsible
                headingText={t("createClassic.theCupHow", "How the cup works")}
              >
                <p>
                  {t(
                    "createClassic.theCupHowExplain",
                    "In the first round of the cup each qualifying team will be randomly drawn against another.  The winner (the team with the highest Round score minus any transfer points), will progress to the second round of the cup and another random draw.  The loser will be eliminated from the cup.  This process continues until the final round of the cup when there will only be two teams remaining."
                  )}
                </p>
                <p>
                  {t(
                    "createClassic.theCupHowExplain1",
                    "In the case of a draw, then the following tie-breaks will be applied until a winner is found:"
                  )}
                </p>
                <ol>
                  <li>
                    {t(
                      "createClassic.theCupHowExplain2",
                      "Most goals scored in the round"
                    )}
                  </li>
                  <li>
                    {t(
                      "createClassic.theCupHowExplain3",
                      "Fewest goals conceded in the round"
                    )}
                  </li>
                  <li>
                    {t("createClassic.theCupHowExplain4", "Virtual coin toss")}
                  </li>
                </ol>
              </Collapsible>
            </form>
          </Copy>
        </Main>
      </Wrapper>
    );
  }
}

export { CreateClassic as CreateClassicTest };

const mapStateToProps = (state: RootState) => ({
  error: getCreateClassicLeagueError(state),
  events: getEvents(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  createClassicLeague: (data: ICreateClassicLeagueData) =>
    dispatch(createClassicLeague(data)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateClassic)
);
