import { hideVisually, size } from "polished";
import * as React from "react";
import styled, { css } from "styled-components/macro";
import theme from "../theme";

export const FieldWrap = styled.div`
  margin: ${theme.space[4]} 0;
`;

const fieldStyles = {
  display: "block",
  width: "100%",
  padding: theme.space[2],
  border: theme.borders[0],
  color: theme.colors.white,
  fontFamily: theme.fonts.base,
  fontSize: "16px",

  ":hover, :focus": {
    borderColor: `${theme.colors.grey}`,
  },
};

interface ILabelProps {
  labelVisbility?: boolean;
}

export const FieldLabel = styled.label<ILabelProps>`
  display: block;
  margin-bottom: ${theme.space[1]};
  color: ${theme.colors.white};
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;

  ${({ labelVisbility }) => labelVisbility === false && hideVisually};
`;

const Hint = styled.span`
  margin-bottom: ${theme.space[1]};
  display: block;
  color: ${theme.colors.white};
  font-weight: 100;
  font-size: 14px;
`;

interface InputProps {
  hasErrors?: boolean;
  required?: boolean;
}

export const Input = styled.input<InputProps>`
  ${fieldStyles}
  border-radius: ${theme.radii[1]};
  color: ${theme.colors.black};
  ${(props) =>
    props.hasErrors &&
    css`
      border-color: ${theme.colors.error};

      &:focus {
        border-color: ${theme.colors.error};
      }
    `}
  box-sizing: border-box;
`;

export const SelectWrap = styled.div`
  position: relative;
  z-index: ${theme.zIndex.select.wrap};
  border: ${theme.borders[0]};
  border-radius: ${theme.radii[1]};
  background-color: ${theme.colors.lighterBlue};

  :hover {
    border-color: ${theme.colors.grey};
  }

  ::after {
    content: "";
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8"><g fill="none" fill-rule="evenodd" transform="translate(-5 -8)"><polygon points="0 24 24 24 24 0 0 0"/><polygon fill="black" fill-rule="nonzero" points="12 13.172 7.05 8.222 5.636 9.636 12 16 18.364 9.636 16.95 8.222"/></g></svg>')
      no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 14px;
    height: 8px;
    background-size: contain;
  }
`;

// Unable to extend SelectWrap for some reason
const InlineSelectWrap = styled.div`
  position: relative;
  display: inline-block;
  z-index: ${theme.zIndex.select.wrap};
  border: ${theme.borders[0]};
  border-radius: 5px;
  background-color: ${theme.colors.lighterBlue};

  :hover {
    border-color: ${theme.colors.grey};
  }

  ::after {
    content: "";
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8"><g fill="none" fill-rule="evenodd" transform="translate(-5 -8)"><polygon points="0 24 24 24 24 0 0 0"/><polygon fill="black" fill-rule="nonzero" points="12 13.172 7.05 8.222 5.636 9.636 12 16 18.364 9.636 16.95 8.222"/></g></svg>')
      no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 14px;
    height: 8px;
    background-size: contain;
  }
`;

export const Select = styled.select`
  ${fieldStyles}
  position: relative;
  z-index: ${theme.zIndex.select.select};
  border: 0;
  border-radius: 0;
  background: none;
  appearance: none;
  cursor: pointer;
  color: ${theme.colors.black};

  ::-ms-expand {
    display: none; /* [1] */
  }
`;

const InlineSelect = styled(Select)`
  display: inline-block;
  width: auto;
  padding-right: 40px;
`;

const SearchWrap = styled.div`
  position: relative;
`;

const SearchInput = styled(Input)`
  /* stylelint-disable property-no-vendor-prefix */
  border-radius: ${theme.radii[1]};
  color: ${theme.colors.black};
  -webkit-appearance: textfield;
`;

const SearchIcon = styled.span`
  ${size("17px")}
  content: "";
  background-size: contain;
  position: absolute;
  right: ${(props) => props.theme.space[2]};
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><path fill="%23007E97" d="M16.789,15.763 L12.064,10.994 C14.2876709,8.1921368 13.9322904,4.140569 11.254801,1.76860777 C8.57731153,-0.603353465 4.5124528,-0.467631625 1.99914537,2.07764547 C-0.514162068,4.62292257 -0.598496252,8.68917203 1.807117,11.3364678 C4.21273026,13.9837635 8.26846617,14.2879068 11.042,12.029 L15.736,16.767 C16.0121708,17.0570934 16.470954,17.0691666 16.762,16.794 C17.052681,16.5160968 17.064737,16.0557367 16.789,15.763 L16.789,15.763 Z M6.773,12.0860194 C4.23641324,12.0857011 2.05326977,10.2936268 1.5586779,7.80572583 C1.06408604,5.31782484 2.39579448,2.82700201 4.73939269,1.85652796 C7.08299091,0.886053911 9.78576378,1.7062152 11.194822,3.81543958 C12.6038802,5.92466396 12.3267871,8.73551172 10.533,10.529 C9.53781123,11.5291874 8.18394325,12.0898183 6.773,12.0860194 Z"/></svg>');
`;

export const FieldCols = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
  }
`;

export const FieldCol = styled.div`
  padding-right: ${({ theme }) => theme.space[2]};
  padding-left: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1;
  }
`;

interface ILabelHintProps {
  hint?: React.ReactNode;
  id: string;
  label: React.ReactNode;
  required?: boolean;
  labelVisbility?: boolean;
}

interface IFieldProps extends ILabelHintProps {
  name?: string;
  value?: string | number;
}

interface IInputProps extends IFieldProps {
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  disabled?: boolean;
  readonly?: boolean;
  hasErrors?: boolean;
  required?: boolean;
}

export const LabelHint: React.FC<ILabelHintProps> = ({
  id,
  label,
  hint,
  required = true,
  labelVisbility = true,
}) => (
  <FieldLabel htmlFor={id} labelVisbility={labelVisbility}>
    <span>{`${label}${required ? "*" : ""}`}</span>
    {hint && <Hint>{hint}</Hint>}
  </FieldLabel>
);

const InlineLabel: React.FC<ILabelHintProps> = ({
  id,
  label,
  required = true,
  labelVisbility = true,
}) => (
  <FieldLabel htmlFor={id} labelVisbility={labelVisbility}>
    <span>{`${label}${required && "*"}`}</span>
  </FieldLabel>
);

export const InputField: React.FC<IInputProps> = ({
  hint,
  id,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  type,
  value,
  required = true,
  hasErrors,
  disabled = false,
  readonly = false,
  labelVisbility = true,
}) => (
  <>
    <LabelHint
      id={id}
      label={label}
      hint={hint}
      required={required}
      labelVisbility={labelVisbility}
    />
    <Input
      required={required}
      type={type}
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      maxLength={maxLength}
      disabled={disabled}
      readOnly={readonly}
      hasErrors={hasErrors}
    />
  </>
);

interface ISearchProps extends IFieldProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchField: React.FC<ISearchProps> = ({
  hint,
  id,
  label,
  name,
  onChange,
  required = true,
  value,
  ...rest
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} required={required} />
    <SearchWrap>
      <SearchInput
        type="search"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        {...rest}
      />
      <SearchIcon />
    </SearchWrap>
  </>
);

interface INumberProps extends IFieldProps {
  ariaValueMax?: number;
  ariaValueMin?: number;
  ariaValueNow?: number;
  max?: string | number;
  min?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  step?: string | number;
}

export const NumberField: React.FC<INumberProps> = ({
  ariaValueMax,
  ariaValueMin,
  ariaValueNow,
  hint,
  id,
  label,
  max = "",
  min = "",
  name,
  onChange,
  step = "",
  value,
  required = true,
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} required={required} />
    <Input
      required={required}
      aria-valuemax={ariaValueMax}
      aria-valuemin={ariaValueMin}
      aria-valuenow={ariaValueNow}
      type="number"
      id={id}
      max={max}
      min={min}
      name={name}
      onChange={onChange}
      step={step}
      value={value}
    />
  </>
);

const Checkbox = styled.input`
  ${hideVisually()};

  :hover + span::before {
    border-color: ${(props) => props.theme.colors.blueDark};
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"><path d="M28.051 68.625c-1.199 0-2.324-.449-3.149-1.273L5.254 47.102c-1.875-1.727-1.949-4.648-.149-6.523 1.727-1.875 4.649-1.949 6.524-.148l14.398 10.273c.825.75 2.102.602 2.774-.301L62.926 8.25c1.5-2.023 4.426-2.477 6.449-.898 2.023 1.5 2.477 4.425.898 6.449L31.727 66.75c-.825 1.051-2.024 1.727-3.301 1.801-.153.074-.227.074-.375.074z" fill="%23E8E8E8"/></svg>');
  }

  :focus + span {
    outline: -webkit-focus-ring-color auto 5px;
  }

  :checked + span::before {
    background-color: ${(props) => props.theme.colors.secondary};
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"><path d="M28.051 68.625c-1.199 0-2.324-.449-3.149-1.273L5.254 47.102c-1.875-1.727-1.949-4.648-.149-6.523 1.727-1.875 4.649-1.949 6.524-.148l14.398 10.273c.825.75 2.102.602 2.774-.301L62.926 8.25c1.5-2.023 4.426-2.477 6.449-.898 2.023 1.5 2.477 4.425.898 6.449L31.727 66.75c-.825 1.051-2.024 1.727-3.301 1.801-.153.074-.227.074-.375.074z" fill="white" /></svg>');
  }
`;

interface ICheckboxText {
  hasLogo?: boolean;
}

const CheckboxText = styled.span<ICheckboxText>`
  display: inline-block;
  position: relative;
  padding: 6px 0 6px 42px;
  line-height: 1.6;

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    border: ${theme.borders[0]};
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle;
  }

  ${({ hasLogo }) =>
    hasLogo &&
    css`
      display: flex;
      align-items: center;
    `}
`;

interface ICheckboxProps {
  checked?: boolean;
  id: string;
  label: React.ReactNode;
  name?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  logo?: any;
  hideLabel?: boolean;
}

const LogoImg = styled.img`
  height: 29px;
  width: auto;
  max-width: 100%;
`;

export const CheckboxField: React.SFC<ICheckboxProps> = ({
  id,
  onChange,
  label,
  checked,
  logo,
  hideLabel = false,
}) => {
  let labelStyles = {};
  if (hideLabel) {
    labelStyles = hideVisually;
  }

  return (
    <div>
      <label htmlFor={id}>
        <Checkbox
          type="checkbox"
          id={id}
          role="checkbox"
          checked={checked}
          aria-checked={checked}
          onChange={onChange}
        />
        <CheckboxText hasLogo={logo}>
          {hideLabel === false && <span style={labelStyles}>{label}</span>}
          {logo && <LogoImg src={logo} />}
        </CheckboxText>
      </label>
    </div>
  );
};

CheckboxField.defaultProps = {
  checked: false,
};

export const Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
`;

interface ILabelLegendProps {
  children: React.ReactNode;
  legend: string;
}

export const LabelLegend: React.SFC<ILabelLegendProps> = ({
  children,
  legend,
}) => (
  <Fieldset>
    <legend>
      <span>{legend}</span>
    </legend>
    {children}
  </Fieldset>
);

const Radio = styled.input`
  ${hideVisually()};

  &:hover + span::before {
    background-color: #e5e5e5;
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.blueDarker1};
  }

  &:focus + span {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:checked + span::before {
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.blueDarker1};
    background-color: ${(props) => props.theme.colors.blue};
  }
`;

const RadioText = styled.span`
  display: inline-block;
  position: relative;
  padding: 6px 0 6px 42px;
  line-height: 1.6;

  &::before {
    /* [1] */
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 23px;
    height: 23px;
    transform: translateY(-50%);
    border: 5px solid white;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #e5e5e5;
    content: "";
    vertical-align: middle;
  }
`;

const StyledFieldFeedback = styled.p`
  color: ${theme.colors.error};
  margin-top: 0;
`;

interface IOptions {
  checked?: boolean;
  id: string;
  label: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  value: string;
}
interface IRadioGroupProps {
  legend: React.ReactNode;
  name: string;
  options: IOptions[];
}

export const RadioGroup: React.SFC<IRadioGroupProps> = ({
  legend,
  name,
  options,
}) => (
  <Fieldset>
    <legend>
      <FieldLabel>{legend}</FieldLabel>
    </legend>
    {options.map((o) => (
      <div key={o.id}>
        <label htmlFor={o.id}>
          <Radio
            type="radio"
            id={o.id}
            name={name}
            value={o.value}
            role="radio"
            aria-checked={o.checked}
            onChange={o.onChange}
            checked={o.checked}
          />
          <RadioText>{o.label}</RadioText>
        </label>
      </div>
    ))}
  </Fieldset>
);

interface ISelectProps extends IFieldProps {
  children: React.ReactNode;
  onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  labelVisbility?: boolean;
}

export const SelectField: React.FC<ISelectProps> = ({
  children,
  hint,
  id,
  label,
  onBlur,
  onChange,
  value,
  labelVisbility = true,
  required = true,
}) => (
  <>
    <LabelHint
      id={id}
      label={label}
      hint={hint}
      required={required}
      labelVisbility={labelVisbility}
    />
    <SelectWrap>
      <Select id={id} onBlur={onBlur} onChange={onChange} value={value}>
        {children}
      </Select>
    </SelectWrap>
  </>
);

export const InlineSelectField: React.FC<ISelectProps> = ({
  children,
  id,
  label,
  onChange,
  value,
}) => (
  <>
    <InlineLabel id={id} label={label} />
    <InlineSelectWrap>
      <InlineSelect id={id} onChange={onChange} value={value}>
        {children}
      </InlineSelect>
    </InlineSelectWrap>
  </>
);

interface IFieldFeedback {
  children: React.ReactNode;
}

export const FieldFeedback: React.FC<IFieldFeedback> = ({ children }) => (
  <StyledFieldFeedback>{children}</StyledFieldFeedback>
);
