import styled from "styled-components/macro";

const SaveBar = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};
  padding-right: ${({ theme }) => theme.space[2]};
  padding-left: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 30%;
    margin-left: 30%;
  }
`;

export default SaveBar;
