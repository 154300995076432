import * as React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components/macro";
import { RootState } from "../core/store";
import { IGroupFixture } from "../core/store/fixtures/types";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeamsById } from "../core/store/teams/types";
import Badge from "./Badge";
import { formatRawAsISO } from "../core/utils/datetime";
import { formatInTimeZone } from "date-fns-tz";

const StyledFixture = styled.div<IStyledFixtureProps>`
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.blueDarker3};

  ${(props) =>
    props.isOpen &&
    css`
      padding: 0px;
      background-color: ${({ theme }) => theme.colors.yellow};
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: relative;
  }
`;

const Teams = styled.div`
  display: flex;
  margin: 0.7rem 0;
  gap: 0.3rem;
  align-items: center;
`;

const Team = styled.div`
  flex: 1 1 calc(50% - (6.6rem / 2));
  text-align: left;
`;

const TeamH = styled(Team)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
`;

const TeamA = styled(Team)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TeamName = styled.abbr<IStyledFixtureProps>`
  vertical-align: middle;
  font-family: ${({ theme }) => theme.fonts.base};
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;

  ${(props) =>
    props.isOpen &&
    css`
      color: ${({ theme }) => theme.colors.black};
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    &:after {
      content: attr(title);
    }
    span {
      display: none;
    }
  }
`;

const FixtureBadge = styled.div`
  display: inline-block;
  margin-right: 0.6rem;
  margin-left: 0.6rem;
  width: 40px;
  height: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
  }
`;

const FixtureKOTime = styled.time`
  flex: 1 0 6.6rem;
  display: inline-block;
  margin: 0 ${({ theme }) => `${theme.space[2]}`};
  padding: 0.9rem ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  line-height: 1.1;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: 1rem 1.6rem 0.7rem;
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }
`;

const FixtureScore = styled.div`
  flex: 1 0 6.6rem;
  display: flex;
  margin: 0 ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.primary};
`;

const FixtureScoreTeam = styled.span`
  flex: 1;
  padding: 0 ${({ theme }) => theme.space[2]};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: bold;
  line-height: 1;
  text-align: center;

  :first-child {
    border-right: ${({ theme }) => theme.borders[0]};
  }
`;

interface IPropsFromState {
  teamsById: ITeamsById;
}

interface IOwnProps {
  fixture: IGroupFixture;
}

interface IStyledFixtureProps {
  isOpen?: boolean;
}

type Props = IPropsFromState & IOwnProps & IStyledFixtureProps;

const Fixture: React.FC<Props> = ({ fixture, teamsById, isOpen }) => {
  return (
    <StyledFixture isOpen={isOpen}>
      <Teams>
        <TeamH>
          <TeamName isOpen={isOpen} title={teamsById[fixture.team_h].name}>
            <span>{teamsById[fixture.team_h].short_name}</span>
          </TeamName>
          <FixtureBadge>
            <Badge
              team={teamsById[fixture.team_h]}
              isPresentation={true}
              sizes="(min-width: 1024px) 40px, 30px"
            />
          </FixtureBadge>
        </TeamH>
        {fixture.started && fixture.team_h_score !== null ? (
          <FixtureScore>
            <FixtureScoreTeam>{fixture.team_h_score}</FixtureScoreTeam>
            <FixtureScoreTeam>{fixture.team_a_score}</FixtureScoreTeam>
          </FixtureScore>
        ) : fixture.kickoff_time ? (
          <FixtureKOTime dateTime={formatRawAsISO(fixture.kickoff_time)}>
            {formatInTimeZone(
              fixture.kickoffDate,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              "HH:mm"
            )}
          </FixtureKOTime>
        ) : (
          <FixtureKOTime as="span">-</FixtureKOTime>
        )}
        <TeamA>
          <FixtureBadge>
            <Badge
              team={teamsById[fixture.team_a]}
              isPresentation={true}
              sizes="(min-width: 1024px) 40px, 30px"
            />
          </FixtureBadge>
          <TeamName isOpen={isOpen} title={teamsById[fixture.team_a].name}>
            <span>{teamsById[fixture.team_a].short_name}</span>
          </TeamName>
        </TeamA>
      </Teams>
    </StyledFixture>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  teamsById: getTeamsById(state),
});

export default connect(mapStateToProps)(Fixture);
