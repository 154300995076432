import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import {
  getElementControls,
  getElementsFromControls,
} from "../../core/store/elements/reducers";
import { updateElementControlsAndMaxCost } from "../../core/store/elements/thunks";
import {
  IElementControls,
  IElementDataFromControls,
} from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import BoldLink from "../BoldLink";
import ElementDialogButton from "../ElementDialogButton";
import ElementShirt from "../ElementShirt";
import { ElementRow, ElementTable, PrimaryCell } from "../ElementTable";
import SubHeading from "../SubHeading";
import { Ellipsis } from "../Utils";
import Panel from "../Panel";
import StatusPanel from "./StatusPanel";

const AvailabilityTable = styled(ElementTable)`
  th:not(:nth-child(3)) {
    text-align: center;
  }

  th {
    :first-of-type {
      border-top-left-radius: ${({ theme }) => theme.radii[2]};
    }

    :last-of-type {
      border-top-right-radius: ${({ theme }) => theme.radii[2]};
    }
  }
`;

const StatusCol = styled.th`
  width: 12%;
`;

const ShirtCol = styled.th`
  width: 16%;
`;

const PlayerCol = styled.th`
  width: 36%;
`;

const PosCol = styled.th`
  width: 20%;
`;

const ClubCol = styled.th`
  width: 20%;
`;

const ShirtCell = styled.td`
  text-align: right;
`;

interface IPropsFromState {
  controls: IElementControls;
  elements: IElementDataFromControls;
  elementTypesById: IElementTypesById;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

type Props = IPropsFromState & IPropsFromDispatch & WithTranslation;

class PlayerAvailability extends React.Component<Props> {
  public componentDidMount() {
    this.props.updateControlsAndMaxCost({
      ...this.props.controls,
      filter: "all",
      sort: "news_added",
      search: "",
    });
  }

  public render() {
    const { elements, elementTypesById, t, teamsById } = this.props;
    return (
      <StatusPanel>
        <StatusPanel.Header
          link={
            <BoldLink to="/the-scout/player-news">
              {t("playerAvailability.more", "more")}
            </BoldLink>
          }
        >
          <SubHeading>
            {t("playerAvailability.heading", "Player Availability")}
          </SubHeading>
        </StatusPanel.Header>
        <Panel>
          <StatusPanel.Body>
            <AvailabilityTable>
              <thead>
                <tr>
                  <StatusCol>&nbsp;</StatusCol>
                  <ShirtCol>&nbsp;</ShirtCol>
                  <PlayerCol>
                    {t("playerAvailability.player", "Player")}
                  </PlayerCol>
                  <PosCol>
                    {t("playerAvailability.postitionShort", "Pos")}
                  </PosCol>
                  <ClubCol>{t("playerAvailability.club", "Club")}</ClubCol>
                </tr>
              </thead>
              <tbody>
                {elements.data.slice(0, 16).map((e) => (
                  <ElementRow key={e.id}>
                    <td>
                      <ElementDialogButton elementId={e.id} variant="list" />
                    </td>
                    <ShirtCell>
                      <ElementShirt elementId={e.id} sizes="24px" />
                    </ShirtCell>
                    <td>
                      <Ellipsis>{e.web_name}</Ellipsis>
                    </td>
                    <PrimaryCell>
                      {elementTypesById[e.element_type].singular_name_short}
                    </PrimaryCell>
                    <PrimaryCell>{teamsById[e.team].short_name}</PrimaryCell>
                  </ElementRow>
                ))}
              </tbody>
            </AvailabilityTable>
          </StatusPanel.Body>
        </Panel>
      </StatusPanel>
    );
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => ({
  controls: getElementControls(state),
  elements: getElementsFromControls(state),
  elementTypesById: getElementTypesById(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PlayerAvailability)
);
