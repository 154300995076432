import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getJoinPublicLeagueError } from "../../core/store/leagues/reducers";
import { joinPublicLeague } from "../../core/store/leagues/thunks";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";

interface IPropsFromState {
  error: IError | null;
  settings: ISettings;
}

interface IPropsFromDispatch {
  joinPublicLeagueClassic: () => void;
  joinPublicLeagueH2H: () => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

const JoinPublic: React.FC<Props> = ({
  error,
  joinPublicLeagueClassic,
  joinPublicLeagueH2H,
  settings,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Main>
        <Copy>
          <Title>{t("joinPublic.title", "Join public league")}</Title>
          {error ? (
            error.badRequest &&
            error.badRequest.non_field_errors &&
            error.badRequest.non_field_errors[0].code ===
              "public_league_max_exceeded" ? (
              <Box mb={2}>
                <Alert type="error">
                  {t(
                    "joinPublic.error",
                    "You may only enter {{ publicLeagueMax }} public leagues",
                    { publicLeagueMax: settings.league_join_public_max }
                  )}
                </Alert>
              </Box>
            ) : (
              <p>{JSON.stringify(error)}</p>
            )
          ) : (
            ""
          )}
          <Box mb={4}>
            <p>
              {t(
                "joinPublic.p1",
                "Public leagues allow you to compete against {{ maxSizePublicClassic }} randomly assigned game players in a classic league and up to {{ maxSizePublicH2H }} in head-to-head leagues. You can join up to {{ leagueJoinPublicMax }} public leagues.",
                {
                  maxSizePublicClassic: settings.league_max_size_public_classic,
                  maxSizePublicH2H: settings.league_max_size_public_h2h,
                  leagueJoinPublicMax: settings.league_join_public_max,
                }
              )}
            </p>
            <p>
              <strong>
                {t(
                  "joinPublic.p2",
                  "Note, you can't remove your team from a public league after the league has started, once the challenge is on there's no quitting."
                )}
              </strong>
            </p>
          </Box>

          <Box mb={4}>
            <SubHeading>
              {t("joinPublic.classic.title", "Classic scoring")}
            </SubHeading>
            <p>
              {t(
                "joinPublic.classic.p1",
                "In a league with classic scoring, teams are ranked based on their total points in the game. You can join or leave a league with classic scoring at any point during the season"
              )}
            </p>
            <Button onClick={joinPublicLeagueClassic}>
              {t("joinPublic.classic.button", "Join classic league")}
            </Button>
          </Box>
          <SubHeading>
            {t("joinPublic.h2H.button", "Head-to-Head scoring")}
          </SubHeading>
          <p>
            {t(
              "joinPublic.h2H.p1",
              "In a league with head-to-head scoring, every team plays a match against another team in the league each Round. The match result is based on the Round score of each team minus any transfer points spent preparing for the Round."
            )}
          </p>
          <Button onClick={joinPublicLeagueH2H}>
            {t("joinPublic.h2H.join.button", "Join head-to-head league")}
          </Button>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export { JoinPublic as JoinPublicTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  error: getJoinPublicLeagueError(state),
  settings: getSettings(state) as ISettings,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  joinPublicLeagueClassic: () => dispatch(joinPublicLeague({ scoring: "c" })),
  joinPublicLeagueH2H: () => dispatch(joinPublicLeague({ scoring: "h" })),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinPublic);
