import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getLoginError, getPlayerData } from "../core/store/player/reducers";
import { login } from "../core/store/player/thunks";
import { ILoginData } from "../core/store/player/types";
import Button from "./Button";
import Copy from "./Copy";

const LoggedInWrap = styled.div`
  margin-left: ${({ theme }) => theme.space[2]};
  margin-right: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: 0;
  }
`;

const Promo = styled.div`
  margin-left: ${({ theme }) => theme.space[2]};
  margin-right: ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[4]};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem;
    text-align: left;
  }
`;

const PromoLeft = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(70% - 1rem);
    padding-right: ${({ theme }) => theme.space[4]};
  }
`;

const PromoRight = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(30% - 1rem);
  }
`;

const PromoHeading = styled.h3`
  margin: 0;
  font-size: 2.1rem;
  font-family: ${({ theme }) => theme.fonts.impact};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
`;

const PromoText = styled.p`
  margin-bottom: ${({ theme }) => theme.space[4]};
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-bottom: 0;
  }
`;

const mapState = (state: RootState) => ({
  error: getLoginError(state),
  player: getPlayerData(state),
});

const mapDispatch = {
  login: (data: ILoginData) => login(data),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Login: React.FC<PropsFromRedux> = ({ error, player }) => {
  const { t } = useTranslation();

  if (player && player.id) {
    return (
      <LoggedInWrap>
        <Box mt="60px" mb={3} p="3rem">
          <Copy>
            <p>
              {t(
                "login.loggedInAs",
                `You are logged in as {{ firstName }} {{ lastName }}`,
                {
                  firstName: player.first_name,
                  lastName: player.last_name,
                }
              )}
            </p>
          </Copy>
        </Box>
      </LoggedInWrap>
    );
  }

  return (
    <>
      <Promo>
        <PromoLeft>
          <PromoHeading>
            {t("login.promo.heading", "A New Season of Allsvenskan Fantasy!")}
          </PromoHeading>
          <PromoText>
            {t(
              "login.promo.paragraph",
              "Allsvenskan celebrates 100 years this season, while Allsvenskan Fantasy turns seven. Make this year’s jubilee extra special by following your favourite players in the Fantasy world as well as on the pitch, at the same time as you compete for prizes every round. The best part? It’s free to play."
            )}
          </PromoText>
        </PromoLeft>
        <PromoRight>
          <Button width={1} href="/api/player/social/login/oidc/">
            {t("login.promo.signUpNow", "Play Now")}
          </Button>
        </PromoRight>
      </Promo>
    </>
  );
};

export default connector(Login);
