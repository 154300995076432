import { Link as RouterLink } from "@reach/router";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import {
  getEntry,
  getFanLeagueDetails,
  getPhaseStandingsForEntryPhaseLeague,
  getPrivateClassicCupLeaguesForEntry,
  getPrivateClassicLeaguesForEntry,
  getPrivateH2HLeaguesForEntry,
  getPublicClassicCupLeaguesForEntry,
  getPublicClassicLeaguesForEntry,
  getPublicH2HLeaguesForEntry,
  getSystemClassicCupLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../core/store/entries/reducers";
import {
  fetchEntryPhaseStandings,
  fetchEntrySummary,
} from "../core/store/entries/thunks";
import {
  IEntry,
  IEntryPhaseStanding,
  IFanLeagueDetails,
  ILeagueEntry,
} from "../core/store/entries/types";
import { getCurrentEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getTotalPlayers } from "../core/store/game/reducers";
import { fetchMyTeam } from "../core/store/my-team/thunks";
import { ITransferState } from "../core/store/my-team/types";
import { getPhases } from "../core/store/phases/reducers";
import { IPhase } from "../core/store/phases/types";
import { getPlayerData } from "../core/store/player/reducers";
import { getTransferState } from "../core/store/squad/reducers";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeamsById } from "../core/store/teams/types";
import { getURL } from "../core/store/urls/reducers";
import { fetchURL } from "../core/store/urls/thunks";
import { IContent } from "../core/store/urls/types";
import { integerToMoney } from "../core/utils/money";
import Badge from "./Badge";
import Button from "./Button";
import Flag from "./Flag";
// import { kitFromEntry } from "./EntryUpdate";
import Footnote from "./Footnote";
import { ControlArrowRight } from "./icons/Arrows";
import Movement from "./leagues/Movement";
import MyLeagueCupSummary from "./leagues/MyLeagueCupSummary";
import { LeagueSummaryTable } from "./leagues/Styles";
import {
  getBroadcasterLeagues,
  getFilteredSystemClassicLeagues,
} from "./leagues/utils";
import Link from "./Link";
import LogoBar from "./LogoBar";
import Panel from "./Panel";
import filterOnSSL from "./scout/filterOnSSL";
import TabHeading from "./TabHeading";
import Table from "./Table";
import EntryTabs from "./tabs/EntryTabs";
import TabPanel from "./tabs/TabPanel";
import { VisuallyHidden } from "./Utils";

const Parser = require("rss-parser");
const parser = new Parser();
const phaseSponsorLeague = 3; // if we have a new sponsor change to the new phase
const leagueSponsorLeague = 315; // check this and change to the overall league

const EntryFullName = styled.h2`
  margin-top: 0;
  margin-bottom: ${({ theme }) => `${theme.space[1]}`};
  line-height: 24px;
  font-size: 20px;

  font-family: ${({ theme }) => theme.fonts.impact};
  text-transform: uppercase;
`;

const EntryName = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  padding-right: ${({ theme }) => theme.space[2]};
  font-size: 17px;
  font-family: ${({ theme }) => theme.fonts.impact};
  text-transform: uppercase;
  line-height: 20px;
  overflow-wrap: anywhere;
`;

const DataListItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]} 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const DataListHeading = styled.h5`
  flex: 1 0 65%;
  padding: 0 ${({ theme }) => theme.space[2]};
`;

const DataListValue = styled.div`
  flex: 1 0 35%;
  padding: 0 ${({ theme }) => theme.space[2]};
`;

const BadgeWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.space[1]};
  bottom: 0;
  right: ${({ theme }) => theme.space[2]};

  img {
    width: auto;
    height: 80%;
  }
`;

const TableFixed = styled(Table)`
  table-layout: fixed;
`;

const MovementCell = styled.td`
  width: 16%;
`;

const Rank = styled.td`
  width: 30%;
  font-weight: 800;
`;

const Name = styled.td`
  width: 54%;
  overflow-wrap: break-word;
  hyphens: auto;
`;

const linkToLeague = (l: ILeagueEntry) =>
  `/leagues/${l.id}/standings/${l.scoring}`;

const linkToCPhase = (
  l: ILeagueEntry,
  phase: number,
  pageNewEntries: number,
  pageStandings: number
) =>
  `/leagues/${l.id}/standings/c?phase=${phase}&page_new_entries=${pageNewEntries}&page_standings=${pageStandings}`;

const boldStyles = css`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`;

const BoldText = styled.span`
  ${boldStyles}
`;

const StyledLink = styled(Link)`
  ${boldStyles}
`;

const StyledTdFixedWidth = styled.td`
  width: 33%;
`;
const RSSArticleTitle = styled.h4`
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1;
  margin-top: ${({ theme }) => theme.space[2]};
`;

const RSSArticle = styled.article`
  padding: ${({ theme }) => theme.space[2]};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  color: #000;
`;
const RSSArticleLink = styled.a`
  color: #000;
  text-decoration: none;
`;

const RSSArticleFigure = styled.figure`
  width: 100%;
  height: auto;
  margin: 0;
`;

const RSSArticleImg = styled.img`
  max-width: 100%;
`;

interface ILeagueSummaryProps {
  title: React.ReactNode;
  leagues: ILeagueEntry[];
}

const RSSArticleDesc = styled.p`
  margin-bottom: 0;
`;
interface IRowProps {
  leagueEntry: ILeagueEntry;
}

const MovementHead = styled.th`
  width: 16%;
`;

const RankHead = styled.th`
  width: 30%;
`;

const NameHead = styled.th`
  width: 54%;
`;

const providerUrls: { [key: string]: string } = {
  217: "https://feeds.feedburner.com/Hif-Feed",
  993: "https://www.mff.se/feed/",
  1987: "https://www.hammarbyfotboll.se/feed/aktuelltrss/",
  3056: "https://www.siriusfotboll.se/category/app/feed",
  244: "https://ifkgoteborg.se/feed/",
  375: "https://elfsborg.se/feed/indexpush",
  1959: "https://bkhacken.se/feed",
  1406: "https://dif.se/specialsidor/rss?rss=Nyheter-fran-dif-se",
  2596: "https://oskfotboll.se/4.2e99a75414ca28e954a6834c/12.2e99a75414ca28e954a68368.portlet?state=rss&sv.contenttype=text/xml;charset=UTF-8",
  2466: "https://falkenbergsff.se/feed/?rss2",
  483: "https://ifknorrkoping.se/feed/",
  2594: "https://kalmarff.se/feed/kff-rss/",
  5576: "https://ostersundsfk.se/category/livekanalen/feed/",
  195: "https://www.aikfotboll.se/rss/app",
  6810: "https://www.boisfc.nu/category/herr/feed/",
  2360: "https://allsvenskan.se/fantasy-feed",
  466: "https://www.hbk.se/modules/rss/HBKRSS2.php",
  2961: "https://degerforsif.se/feed/",
  2598: "https://gifsundsvall.se/feed/",
  6210: "https://ifkvarnamo.se/feed.xml",
  3055: "https://afc-eskilstuna.se/team/MaVKByEo/news/feed.xml",
  10745: "https://dalkurd.se/feed/",
  2352: "https://allsvenskan.se/fantasy-feed",
  2945: "https://allsvenskan.se/fantasy-feed",
  3058: "https://jonkopingssodra.se/feed/",
  1556: "https://landskronabois.se/feed/",
  10746: "https://www.norrbyif.se/rss/?SID=6275",
  417: "https://oisfotboll.se/nyheter/feed",
  1486: "https://ostersif.se/a/c/oster-app.rss",
  3722: "https://allsvenskan.se/fantasy-feed",
  1155: "https://www.trelleborgsff.se/rss/nyheter",
  7132: "https://allsvenskan.se/fantasy-feed",
  6211: "https://www.vskfotboll.nu/feed/?rss",
  13216: "https://allsvenskan.se/fantasy-feed",
  7121: "https://allsvenskan.se/fantasy-feed",
  670: "ttps://bpfotboll.se/category/app/feed",
  2597: "https://www.gais.se/nyheter/rss.xml",
};

interface IPropsFromState {
  entry: IEntry | null;
  mine: Boolean;
  now: IEvent | null;
  privateClassicLeagues: ILeagueEntry[];
  privateH2HLeagues: ILeagueEntry[];
  publicClassicLeagues: ILeagueEntry[];
  publicH2HLeagues: ILeagueEntry[];
  systemClassicLeagues: ILeagueEntry[];
  teamsById: ITeamsById;
  totalPlayers: number;
  transfersState: ITransferState | null;
  fanLeagueRssObj: IContent | null;
  fanLeagueDetails: IFanLeagueDetails | null;
  entryPhaseStandings: IEntryPhaseStanding | undefined;
  phases: IPhase[];
  privateClassicCupLeagues: ILeagueEntry[];
  publicClassicCupLeagues: ILeagueEntry[];
  systemClassicCupLeagues: ILeagueEntry[];
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
  fetchEntryPhaseStandings: (entryId: number, phaseId: number) => void;
  fetchMyTeam: () => void;
  fetchUrl: (url: string) => void;
}

interface IOwnProps {
  entryId: number;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps & WithTranslation;

class Entry extends React.Component<Props, IState> {
  public state: IState = { favTeamArticle: null };

  public componentDidMount() {
    const { entry, fanLeagueDetails } = this.props;

    if (entry && fanLeagueDetails) {
      // If entry && fanLeagueDetails - Fetch the RSS content for fan league team
      this.props.fetchUrl(providerUrls[fanLeagueDetails.team.code]);
    }

    if (this.props.mine && !this.props.transfersState) {
      this.props.fetchMyTeam();
    }
    if (!this.props.entry) {
      this.props.fetchEntrySummary(this.props.entryId);
    }
    if (entry && phaseSponsorLeague) {
      this.props.fetchEntryPhaseStandings(entry.id, phaseSponsorLeague);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.fanLeagueRssObj !== this.props.fanLeagueRssObj) {
      this.handleParseContent(this.props.fanLeagueRssObj);
    }
  }

  handleParseContent = async (rss: any) => {
    try {
      const rssFeed = await parser.parseString(rss.text);
      const lastItem = filterOnSSL(rssFeed.items)[0];
      this.setState({
        favTeamArticle: lastItem,
      });
    } catch (err) {}
  };

  public render() {
    const {
      entry,
      privateClassicLeagues,
      publicClassicLeagues,
      privateH2HLeagues,
      publicH2HLeagues,
      systemClassicLeagues,
      totalPlayers,
      transfersState,
      mine = false,
      now,
      t,
      fanLeagueDetails,
      entryPhaseStandings,
      phases,
      privateClassicCupLeagues,
      publicClassicCupLeagues,
      systemClassicCupLeagues,
    } = this.props;

    const { favTeamArticle } = this.state;
    const leagueSponsorName = t("leagueSponsorName", "154 League");

    if (!entry) {
      return null;
    }
    // const kit = kitFromEntry(entry);

    // Split broadcaster leagues out from system classic leagues
    const broadcasterLeagues = getBroadcasterLeagues(systemClassicLeagues);
    const filteredSystemClassicLeagues = getFilteredSystemClassicLeagues(
      systemClassicLeagues,
      broadcasterLeagues
    );

    const hasCupLeagues =
      privateClassicCupLeagues.length > 0 ||
      publicClassicCupLeagues.length > 0 ||
      systemClassicCupLeagues.length > 0;

    // Split broadcaster cup leagues out from system classic cup leagues
    const broadcasterCupLeagues = getBroadcasterLeagues(
      systemClassicCupLeagues
    );
    const filteredSystemCupLeagues = getFilteredSystemClassicLeagues(
      systemClassicCupLeagues,
      broadcasterCupLeagues
    );
    const phaseObj = phases.filter((p) => p.id === phaseSponsorLeague);

    const LeagueSummary: React.FC<ILeagueSummaryProps> = ({
      leagues,
      title,
    }) => (
      <div>
        <TabHeading title={title} />
        <LeagueSummaryTable>
          <thead>
            <tr>
              <MovementHead scope="col">
                <VisuallyHidden>
                  {t("myTeam.leagueSummary.movement", "Movement")}
                </VisuallyHidden>
              </MovementHead>
              <RankHead scope="col">
                {t("myTeam.leagueSummary.rank", "Rank")}
              </RankHead>
              <NameHead scope="col">
                {t("myTeam.leagueSummary.league", "League")}
              </NameHead>
            </tr>
          </thead>
          <tbody>
            {leagues.map((l) => (
              <LeagueSummaryRow key={l.id} leagueEntry={l} />
            ))}
          </tbody>
        </LeagueSummaryTable>
      </div>
    );

    const LeagueSummaryRow: React.FC<IRowProps> = ({ leagueEntry }) => (
      <tr>
        <MovementCell>
          <Movement
            lastRank={leagueEntry.entry_last_rank}
            rank={leagueEntry.entry_rank}
          />
        </MovementCell>
        <Rank>
          {leagueEntry.entry_rank
            ? leagueEntry.entry_rank.toLocaleString()
            : null}
        </Rank>
        <Name>
          <StyledLink to={linkToLeague(leagueEntry)}>
            {leagueEntry.name}
          </StyledLink>
        </Name>
      </tr>
    );

    return (
      <Box px={2}>
        <EntryFullName>
          {entry.player_first_name} {entry.player_last_name}
        </EntryFullName>

        <LogoBar justifyContent="space-between">
          <EntryName>{entry.name}</EntryName>
          <Flag code={entry.player_region_iso_code_short} />
        </LogoBar>

        <Box mt={2} pb={4}>
          <Panel hasKeyline={false}>
            <Panel.Header
              title={t("myTeam.pointsRankings", "Points/Rankings")}
            />
            <Panel.Body>
              <Table size="lg">
                <tbody>
                  <tr>
                    <td>{t("myTeam.overallPoints", "Overall Points")}:</td>
                    <td>
                      <BoldText>{entry.summary_overall_points}</BoldText>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("myTeam.overallRank", "Overall Rank")}:</td>
                    <td>
                      <BoldText>
                        {entry.summary_overall_rank &&
                          entry.summary_overall_rank.toLocaleString()}
                      </BoldText>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("myTeam.totalPlayers", "Total Players")}:</td>
                    <td>
                      <BoldText>{totalPlayers.toLocaleString()}</BoldText>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("myTeam.roundPoints", "Round Points")}:</td>
                    <td>
                      <BoldText>{entry.summary_event_points}</BoldText>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Panel.Body>
            <Panel.Footer>
              <StyledLink to={`/entry/${entry.id}/history`}>
                {t("myTeam.viewRoundHistory", "View Round history")}
              </StyledLink>
            </Panel.Footer>
          </Panel>
        </Box>
        {entryPhaseStandings &&
          now &&
          phaseObj &&
          phaseObj[0].start_event <= now.id &&
          phaseObj[0].stop_event >= now.id && (
            <Box pb={4}>
              <Panel hasKeyline={false}>
                <Panel.Header title={leagueSponsorName} />
                <Panel.Body>
                  <TableFixed size="lg">
                    <tbody>
                      <tr>
                        <MovementCell>
                          <Movement
                            lastRank={entryPhaseStandings.last_rank}
                            rank={entryPhaseStandings.rank}
                          />
                        </MovementCell>
                        <Rank>
                          {entryPhaseStandings.rank
                            ? entryPhaseStandings.rank.toLocaleString()
                            : null}
                        </Rank>
                        <Name>
                          <StyledLink
                            to={linkToCPhase(
                              entry.leagues.classic.filter(
                                (l) => l.id === entryPhaseStandings.league_id
                              )[0],
                              entryPhaseStandings.phase,
                              1,
                              1
                            )}
                          >
                            {" "}
                            {
                              entry.leagues.classic.filter(
                                (l) => l.id === entryPhaseStandings.league_id
                              )[0].name
                            }
                          </StyledLink>
                        </Name>
                      </tr>
                    </tbody>
                  </TableFixed>
                </Panel.Body>
              </Panel>
            </Box>
          )}
        {fanLeagueDetails && favTeamArticle && (
          <Box pb={4}>
            <Panel hasKeyline={false}>
              <Panel.Header title={t("myTeam.fanLeague", "Fan League")}>
                <BadgeWrapper>
                  <Badge
                    team={fanLeagueDetails.team}
                    isPresentation={true}
                    sizes="(min-width: 1024px) 40px, 30px"
                  />
                </BadgeWrapper>
              </Panel.Header>
              <Panel.Body>
                <RSSArticle>
                  <RSSArticleLink
                    href={favTeamArticle.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <RSSArticleFigure>
                      {favTeamArticle.enclosure && (
                        <RSSArticleImg
                          src={favTeamArticle.enclosure.url}
                          alt=""
                        />
                      )}
                    </RSSArticleFigure>
                    <RSSArticleTitle>{favTeamArticle.title}</RSSArticleTitle>
                    <RSSArticleDesc>
                      {favTeamArticle.description}
                    </RSSArticleDesc>
                  </RSSArticleLink>
                </RSSArticle>
              </Panel.Body>
              <Panel.Footer>
                <StyledLink to={linkToLeague(fanLeagueDetails.league)}>
                  {t("myTeam.viewFanLeague", "View {{team}} Fan League", {
                    team: fanLeagueDetails.team.name,
                  })}
                </StyledLink>
              </Panel.Footer>
            </Panel>
          </Box>
        )}
        <Box pb={4}>
          <Panel>
            <Panel.Header title={t("myTeam.leaguesCups", "Leagues & Cups")} />

            <EntryTabs>
              <TabPanel
                label={t("myTeam.leaguesTab", "Leagues")}
                link="leagues"
              >
                {broadcasterLeagues.length > 0 && (
                  <LeagueSummary
                    title={t("myTeam.broadcaster", "Broadcaster League")}
                    leagues={broadcasterLeagues}
                  />
                )}
                {privateClassicLeagues.length > 0 && (
                  <LeagueSummary
                    title={t(
                      "myTeam.privateClassic",
                      "Private Classic Leagues"
                    )}
                    leagues={privateClassicLeagues}
                  />
                )}
                {privateH2HLeagues.length > 0 && (
                  <LeagueSummary
                    title={t(
                      "myTeam.privateH2H",
                      "Private Head-to-Head Leagues"
                    )}
                    leagues={privateH2HLeagues}
                  />
                )}
                {publicClassicLeagues.length > 0 && (
                  <LeagueSummary
                    title={t("myTeam.publicClassic", "Public Classic Leagues")}
                    leagues={publicClassicLeagues}
                  />
                )}
                {publicH2HLeagues.length > 0 && (
                  <LeagueSummary
                    title={t("myTeam.publicH2H", "Public Head-to-Head Leagues")}
                    leagues={publicH2HLeagues}
                  />
                )}
                <LeagueSummary
                  title={t("myTeam.general", "General Leagues")}
                  leagues={filteredSystemClassicLeagues}
                />
              </TabPanel>
              <TabPanel label={t("leaguesNav.cups", "Cups")} link="cups">
                {hasCupLeagues ? (
                  <>
                    {privateClassicCupLeagues.length > 0 && (
                      <MyLeagueCupSummary
                        entryId={entry.id}
                        leagues={privateClassicCupLeagues}
                        title={t("myTeam.leagueCups", "League cups")}
                      />
                    )}
                    {publicClassicCupLeagues.length > 0 && (
                      <MyLeagueCupSummary
                        entryId={entry.id}
                        leagues={publicClassicCupLeagues}
                        title={t("myTeam.publicCups", "Public cups")}
                      />
                    )}
                    {filteredSystemCupLeagues.length > 0 && (
                      <MyLeagueCupSummary
                        entryId={entry.id}
                        leagues={filteredSystemCupLeagues}
                        title={t("myTeam.generalCups", "General cups")}
                      />
                    )}
                    {broadcasterCupLeagues.length > 0 && (
                      <MyLeagueCupSummary
                        entryId={entry.id}
                        leagues={broadcasterCupLeagues}
                        title={t("myTeam.broadcasterCups", "Broadcaster cups")}
                      />
                    )}
                  </>
                ) : (
                  <Box m={2}>
                    <p>
                      {t("myTeam.none", "None of your leagues have a cup yet.")}
                    </p>
                  </Box>
                )}
              </TabPanel>
            </EntryTabs>
          </Panel>
        </Box>

        <Box pb={4}>
          <Button width={1} to="/leagues" as={RouterLink}>
            {t("myTeam.createAndJoinLeagues", "Create and join leagues")}
          </Button>
        </Box>

        {mine && transfersState && (
          <Box pb={4}>
            <Panel hasKeyline={false}>
              <Panel.Header
                title={t(
                  "myTeam.trans.transfersAndFinance",
                  "Transfers And Finance"
                )}
              />
              <Panel.Body>
                <Table size="lg">
                  <tbody>
                    <tr>
                      <td>{t("myTeam.roundTransfers", "Round transfers")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>{transfersState.made}</BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                    <tr>
                      <td>{t("myTeam.totalTransfers", "Total transfers")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>
                          {entry.last_deadline_total_transfers +
                            transfersState.made}
                        </BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                  </tbody>
                </Table>
              </Panel.Body>
              <Panel.Footer>
                <StyledLink to={`/entry/${entry.id}/transfers`}>
                  {t("myTeam.viewTransferHistory", "View transfer history")}
                </StyledLink>
              </Panel.Footer>
              <Panel.Body>
                <Table size="lg">
                  <tbody>
                    <tr>
                      <td>{t("myTeam.squadValue", "Squad value")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>
                          {integerToMoney(transfersState.value, 10)}
                        </BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                    <tr>
                      <td>{t("myTeam.inTheBank", "In the bank")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>
                          {integerToMoney(transfersState.bank, 10)}
                        </BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                  </tbody>
                </Table>
              </Panel.Body>
            </Panel>
          </Box>
        )}
        {!mine && (
          <Box pb={4}>
            <Panel hasKeyline={false}>
              <Panel.Header
                title={
                  <>
                    {t("myTeam.transfersAndFinance", "Transfers and Finance")}{" "}
                    <sup>*</sup>
                  </>
                }
              />
              <Panel.Body>
                <p />
                <ul>
                  <DataListItem>
                    <DataListHeading>
                      {t("myTeam.totalTransfers", "Total transfers")}:
                    </DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_total_transfers}
                    </DataListValue>
                  </DataListItem>
                </ul>
              </Panel.Body>
              <Panel.Footer>
                <StyledLink to={`/entry/${entry.id}/transfers`}>
                  {t("myTeam.viewTransferHistory", "View transfer history")}
                </StyledLink>
              </Panel.Footer>
              <Panel.Body>
                <ul>
                  <DataListItem>
                    <DataListHeading>
                      {t("myTeam.squadValue", "Squad value")}:
                    </DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_value !== null &&
                      entry.last_deadline_bank !== null
                        ? `kr${integerToMoney(
                            entry.last_deadline_value -
                              entry.last_deadline_bank,
                            10
                          )}`
                        : "-"}
                    </DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListHeading>
                      {t("myTeam.inTheBank", "In the bank")}:
                    </DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_bank !== null
                        ? `kr${integerToMoney(entry.last_deadline_bank, 10)}`
                        : "-"}
                    </DataListValue>
                  </DataListItem>
                </ul>
                <Box mt={1} px={2}>
                  <Footnote>* Values at the last deadline</Footnote>
                </Box>
              </Panel.Body>
            </Panel>
          </Box>
        )}
        {mine && (
          <Box pb={4}>
            <Panel hasKeyline={false}>
              <Panel.Header title={t("myTeam.admin", "Admin")} />
              <Panel.Body>
                {mine && (
                  <Box p={1}>
                    <Box p={1}>
                      <Button
                        width={1}
                        variant="secondary"
                        as={RouterLink}
                        to={`/entry-update`}
                        endIcon={<ControlArrowRight />}
                      >
                        {t("teamDetails", "Team details")}
                      </Button>
                    </Box>
                    <Box p={1}>
                      <Button
                        width={1}
                        variant="secondary"
                        to="/player"
                        as={RouterLink}
                        endIcon={<ControlArrowRight />}
                      >
                        {t("myTeam.userProfile", "User profile")}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Panel.Body>
            </Panel>
          </Box>
        )}
      </Box>
    );
  }
}

export { Entry as EntryTest };

interface IState {
  favTeamArticle: any | null;
}

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => {
  const player = getPlayerData(state);
  const entryId = ownProps.entryId;

  const fanLeagueDetails = getFanLeagueDetails(state, entryId);
  const fanLeagueUrl = fanLeagueDetails
    ? providerUrls[fanLeagueDetails.team.code]
    : null;
  const fanLeagueRssObj = fanLeagueUrl ? getURL(state, fanLeagueUrl) : null;
  const entryPhaseStandings = getPhaseStandingsForEntryPhaseLeague(
    state,
    entryId,
    phaseSponsorLeague,
    leagueSponsorLeague
  );
  return {
    entry: getEntry(state, entryId),
    mine: Boolean(player && player.entry && player.entry === entryId),
    now: getCurrentEvent(state),
    privateClassicLeagues: getPrivateClassicLeaguesForEntry(state, entryId),
    privateClassicCupLeagues: getPrivateClassicCupLeaguesForEntry(
      state,
      entryId
    ),
    privateH2HLeagues: getPrivateH2HLeaguesForEntry(state, entryId),
    publicClassicLeagues: getPublicClassicLeaguesForEntry(state, entryId),
    publicH2HLeagues: getPublicH2HLeaguesForEntry(state, entryId),
    systemClassicLeagues: getSystemClassicLeaguesForEntry(state, entryId),
    teamsById: getTeamsById(state),
    transfersState: getTransferState(state),
    totalPlayers: getTotalPlayers(state),
    fanLeagueRssObj,
    fanLeagueDetails,
    entryPhaseStandings,
    phases: getPhases(state),
    publicClassicCupLeagues: getPublicClassicCupLeaguesForEntry(state, entryId),
    systemClassicCupLeagues: getSystemClassicCupLeaguesForEntry(state, entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchEntryPhaseStandings: (entryId: number, phaseId: number) =>
    dispatch(fetchEntryPhaseStandings(entryId, phaseId)),
  fetchMyTeam: () => dispatch(fetchMyTeam()),
  fetchUrl: (url) => dispatch(fetchURL(url)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Entry)
);
