import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import configureStore from "./core/configureStore";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: "https://3214d121c1a1407a976ec71c9785607c@sentry.io/1881387",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    whitelistUrls: [/allsvenskan\.se/, /ismgames\.com/],
    ignoreErrors: [],
  });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
