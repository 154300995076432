import React from "react";
import { useTranslation } from "react-i18next";
import SubNav from "../SubNav";

const ScoutNav: React.FC = () => {
  const { t } = useTranslation();
  const links = [
    { href: "/the-scout", text: t("scoutNav.news", "News") },
    {
      href: "/the-scout/player-news",
      text: t("scoutNav.availabilty", "Availability"),
    },
  ];
  return <SubNav links={links} />;
};

export default ScoutNav;
