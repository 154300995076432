import styled from "styled-components/macro";
import Table from "../Table";

export const PrimaryWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: ${({ theme }) => `${theme.space[2]} ${theme.space[2]} 0`};
  }
`;

export const LeagueSummaryHeading = styled.h4`
  margin: 0 0 1rem;
  padding: 1.3rem 0;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: 1.3rem;
`;

export const SummaryCupCol = styled.th`
  width: 49%;
`;

export const SummaryRoundCol = styled.th`
  width: 24%;
`;

export const SummaryStatusCol = styled.th`
  width: 27%;
`;

export const LeagueSummaryTable = styled(Table)`
  table-layout: fixed;
  tr,
  td {
    padding: ${({ theme }) => theme.space[3]};
  }

  /* Have to do this because of Table td specificity */
  ${SummaryCupCol} {
    text-align: center;
  }
`;

export const SelectWrap = styled.div`
  margin: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const SelectBox = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 49%;
  }
`;
