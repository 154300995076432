import React from "react";
import styled from "styled-components/macro";
import Copy from "./Copy";

const StyledCollapsible = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  > h5,
  table {
    margin: 0;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: ${({ theme }) => theme.space[3]};
  border: 0;
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: 1.4rem;
  text-align: left;

  :hover {
    color: white;
    background-color: ${({ theme }) => theme.colors.secondary};

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }

  &[aria-expanded="true"] {
    svg {
      transform: rotate(180deg);
      fill: ${({ theme }) => theme.colors.black};
    }
    padding-bottom: ${({ theme }) => theme.space[2]};
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: ${({ theme }) => theme.colors.yellow};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const CollapseIcon = styled.div`
  width: 14px;
  margin-right: 10px;
  flex-shrink: 0;
`;

const CollapsibleContent = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  border: ${({ theme }) => theme.borders[1]};
  border-bottom-right-radius: ${({ theme }) => theme.radii[2]};
  border-bottom-left-radius: ${({ theme }) => theme.radii[2]};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blueDarker3};

  &[aria-hidden] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
`;

// Used to generate unique ids for collapsible content / aria controls
let lastId = 0;
const newId = (prefix = "ism-id") => `${prefix}${++lastId}`;

interface IProps {
  headingText: string;
}

interface IState {
  open: boolean;
}

class Collapsible extends React.Component<IProps, IState> {
  public state = { open: false };
  public id = "";
  public toggle = () => this.setState({ open: !this.state.open });

  componentDidMount() {
    this.id = newId("ism-collapsible-");
  }

  render() {
    const { children, headingText } = this.props;
    return (
      <StyledCollapsible>
        <h5>
          <Button
            type="button"
            onClick={this.toggle}
            aria-expanded={this.state.open}
            aria-controls={this.id}
          >
            <CollapseIcon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                <path
                  fill-rule="nonzero"
                  d="M7 5.172L2.05.222.636 1.636 7 8l6.364-6.364L11.95.222z"
                />
              </svg>
            </CollapseIcon>
            {headingText}
          </Button>
        </h5>
        <CollapsibleContent id={this.id} aria-hidden={!this.state.open}>
          <Copy>{children}</Copy>
        </CollapsibleContent>
      </StyledCollapsible>
    );
  }
}

export default Collapsible;
