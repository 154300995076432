import { Link } from "@reach/router";
import * as React from "react";
import styled, { css } from "styled-components/macro";
import { ControlArrowRight } from "./icons/Arrows";

const BoldLinkStyles = css`
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.yellow};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledBoldHyperlink = styled.a`
  ${BoldLinkStyles}
`;

const StyledBoldLink = styled(Link)`
  ${BoldLinkStyles}
`;

interface IBoldLinkProps {
  children: React.ReactNode;
  to: string;
}

interface IBoldHyperlinkProps {
  children: React.ReactNode;
  href: string;
}

export const BoldHyperlink: React.FC<IBoldHyperlinkProps> = ({
  children,
  href,
}) => (
  <StyledBoldHyperlink href={href}>
    {children}
    <ControlArrowRight />
  </StyledBoldHyperlink>
);

const BoldLink: React.FC<IBoldLinkProps> = ({ children, to }) => (
  <StyledBoldLink to={to}>
    {children}
    <ControlArrowRight />
  </StyledBoldLink>
);

export default BoldLink;
