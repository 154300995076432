import styled from "styled-components/macro";

const Title = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export default Title;
