import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { ReactComponent as FacebookLogo } from "../../img/icons/facebook-round.svg";
import { ReactComponent as TwitterLogo } from "../../img/icons/twitter-round.svg";
import image from "../../img/scouting-hero-1180.png";
import image2x from "../../img/scouting-hero-2360.png";
import Hero from "../Hero";
import { Main, Secondary, Wrapper } from "../Layout";
import LogoBar from "../LogoBar";
import Title from "../Title";
import ScoutNav from "./ScoutNav";
import ScoutProvider from "./ScoutProvider";

const Social = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.primary};
`;

const SocialHeader = styled.header`
  border-top-left-radius: ${({ theme }) => theme.radii[2]};
  border-top-right-radius: ${({ theme }) => theme.radii[2]};
`;

const SocialBody = styled.div`
  padding: ${({ theme }) => theme.space[1]};
`;

const SocialLinks = styled.ul`
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  background-color: white;
`;

const SocialLink = styled.a<ISocialLinkStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.social[props.network].color};
  background-color: ${(props) => props.theme.colors.social[props.network].bg};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const SocialLinkItem = styled.li`
  padding: ${({ theme }) => theme.space[2]};
  list-style-type: none;
`;

const SocialLinkText = styled.span`
  margin-left: 1rem;
  font-size: 1.2rem;
`;

const SubHeading = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 2rem;
  }
`;

interface ISocialLinkStyleProps {
  network: string;
}

interface IProvider {
  link: string;
  url: string;
}

type Props = RouteComponentProps;

const providers: IProvider[] = [
  {
    url: "https://allsvenskan.se/nyheter/category/fantasy/feed/",
    link: "https://allsvenskan.se/fantasy/",
  },
];

const Scout: React.FC<Props> = () => {
  const { t } = useTranslation();
  const twitterFeedId = "twsrc%5Etfw";

  useEffect(() => {
    const twttr = (window as any).twttr;
    if (twttr && twttr.widgets) {
      twttr.widgets.load();
    }
  }, []);

  return (
    <>
      <Wrapper>
        <Box flex={1} pt={4}>
          <ScoutNav />
        </Box>
      </Wrapper>
      <Wrapper>
        <Main>
          <Hero image={image} image2x={image2x} />
          <Box mx={2}>
            <Title>{t("scout.title", "The Scout")}</Title>
          </Box>
          {providers.map((provider: IProvider) => (
            <ScoutProvider
              key={provider.url}
              link={provider.link}
              url={provider.url}
            />
          ))}
        </Main>
        <Secondary>
          <Social>
            <SocialHeader>
              <LogoBar>
                <SubHeading>{t("Allsvenskan on Social")}</SubHeading>
              </LogoBar>
            </SocialHeader>
            <SocialBody>
              <SocialLinks>
                <SocialLinkItem>
                  <SocialLink
                    href="https://www.facebook.com/Allsvenskan/"
                    network="facebook"
                    target="_blank"
                  >
                    <FacebookLogo />
                    <SocialLinkText>
                      {t("Allsvenskan on Facebook")}
                    </SocialLinkText>
                  </SocialLink>
                </SocialLinkItem>
                <SocialLinkItem>
                  <SocialLink
                    href="https://twitter.com/AllsvenskanSE"
                    network="twitter"
                    target="_blank"
                  >
                    <TwitterLogo />
                    <SocialLinkText>
                      {t("Allsvenskan on Twitter")}
                    </SocialLinkText>
                  </SocialLink>
                </SocialLinkItem>
              </SocialLinks>
              <a
                className="twitter-timeline"
                data-height="1200"
                data-link-color="#19CF86"
                href={`https://twitter.com/AllsvenskanSE?ref_src=${twitterFeedId}`}
              >
                {t("Tweets by Allsvenskan")}
              </a>
            </SocialBody>
          </Social>
        </Secondary>
      </Wrapper>
    </>
  );
};

export default Scout;
