import { Link, navigate, RouteComponentProps } from "@reach/router";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box } from "rebass/styled-components";
import { ThunkDispatch } from "../../core/store";
import { bootstrap } from "../../core/store/bootstrap/thunks";
import Button from "../Button";
import Copy from "../Copy";
import { InputField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import { FetchPut } from "./utils";

const MergeCode: React.FC<RouteComponentProps> = () => {
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const { t } = useTranslation();
  const [token, setToken] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    FetchPut("player/otp-validate/", { token }).then(async () => {
      try {
        await reduxDispatch(bootstrap());
      } catch (e) {
        return;
      }
      navigate("../player");
    });
  };
  return (
    <Wrapper>
      <Main>
        <Copy>
          <Title>{t("mergeCode.title", "Merge Account")}</Title>
          <SubHeading>{t("mergCode.subheading", "Enter Code")}</SubHeading>
          <p>
            {t(
              "mergeCode.intro",
              "To complete the account merge, please enter the 6-digit code sent to the email address registered to your old account and click 'Submit code'."
            )}
          </p>
          <form onSubmit={handleSubmit}>
            <InputField
              type="text"
              id="token"
              label={t("mergeCode.label", "Validation code")}
              value={token}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setToken(e.currentTarget.value)
              }
              required={false}
            />
            <Box mt={2} mb={4}>
              <Button type="submit" disabled={!token}>
                {t("mergeCode.button", "Submit code")}
              </Button>
            </Box>
          </form>
          <p>
            <Trans i18nKey="mergeEmail.noemail">
              Didn't get the code? If you do not see the email in your inbox,{" "}
              check your “junk mail” folder or “spam” folder. We make every{" "}
              effort to ensure these emails are delivered. If you still don't{" "}
              receive the code, click <Link to="/player/merge-email">here</Link>{" "}
              to send it again.
            </Trans>
          </p>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export default MergeCode;
