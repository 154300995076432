import { RouteComponentProps } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { RootState, ThunkDispatch } from "../../core/store";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getLeagueAdmin } from "../../core/store/leagues/reducers";
import {
  fetchH2HLeagueForAdmin,
  updateH2HLeague,
} from "../../core/store/leagues/thunks";
import {
  IPrivateLeagueAdmin,
  IUpdateH2HLeagueData,
} from "../../core/store/leagues/types";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";
import AddBan from "./admin/AddBan";
import ChangeAdmin from "./admin/ChangeAdmin";
import Code from "./admin/Code";
import Delete from "./admin/Delete";
import H2HDetails from "./admin/H2HDetails";
import RemoveBan from "./admin/RemoveBan";
import { Box } from "rebass/styled-components";
import { withTranslation, WithTranslation } from "react-i18next";

type OwnProps = RouteComponentProps<{ leagueId: string }>;

interface IPropsFromState {
  league: IPrivateLeagueAdmin | null;
  leagueNumber: number;
  now: IEvent | null;
}

interface IPropsFromDispatch {
  fetchLeague: (leagueId: number) => void;
  updateLeague: (leagueId: number, data: IUpdateH2HLeagueData) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

class AdminClassic extends React.Component<Props> {
  public componentDidMount() {
    if (this.props.leagueNumber) {
      this.props.fetchLeague(this.props.leagueNumber);
    }
  }

  public render() {
    const { league, now, t, updateLeague } = this.props;
    if (!league) {
      return null;
    }
    const started = Boolean(now && now.id >= league.start_event);
    return (
      <Wrapper>
        <Main>
          <Copy>
            <Title>{t("leagues.adminTitle", "League Administration")}</Title>
            {!started && <Code league={league} />}
            <Box mt={4}>
              <H2HDetails league={league} started={started} />
            </Box>
            {!started && (
              <Box mt={4}>
                <AddBan league={league} />
              </Box>
            )}
            {!started && (
              <Box mt={4}>
                <RemoveBan league={league} />
              </Box>
            )}
            {!started && (
              <Box mt={4}>
                <ChangeAdmin
                  league={league}
                  updateAdmin={(entryId: number) =>
                    updateLeague(league.id, {
                      admin_entry: entryId,
                      closed: league.closed,
                      code_privacy: league.code_privacy,
                      ko_rounds: league.ko_rounds,
                      max_entries: league.max_entries,
                      name: league.name,
                      start_event: league.start_event,
                    })
                  }
                />
              </Box>
            )}
            {!started && (
              <Box mt={4}>
                <Delete league={league} />
              </Box>
            )}
          </Copy>
        </Main>
      </Wrapper>
    );
  }
}

export { AdminClassic as AdminClassicTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const leagueNumber =
    ownProps.leagueId && parseInt(ownProps.leagueId, 10)
      ? parseInt(ownProps.leagueId, 10)
      : 0;
  return {
    league: leagueNumber
      ? (getLeagueAdmin(state, leagueNumber) as IPrivateLeagueAdmin)
      : null,
    leagueNumber,
    now: getCurrentEvent(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchLeague: (leagueId: number) => dispatch(fetchH2HLeagueForAdmin(leagueId)),
  updateLeague: (leagueId: number, data: IUpdateH2HLeagueData) =>
    dispatch(updateH2HLeague(leagueId, data)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AdminClassic)
);
