import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { getPlayerData } from "../../core/store/player/reducers";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";

const StitchBox = () => {
  const player = useSelector(getPlayerData);
  const { t } = useTranslation();

  if (!player) {
    return null;
  }

  const isNormalConfirm = player.dirty && !player.stitched;
  const canStitch = !player.dirty && !player.stitched;
  const isConfirmStitch = player.dirty && player.stitched;
  const isStitched = !player.dirty && player.stitched;

  if (isNormalConfirm) {
    return null;
  }

  if (canStitch) {
    return (
      <Box mb={4}>
        <Alert type="warning">
          <Copy>
            <p>
              <strong>
                {t(
                  "stitchBox.canStitch.p1",
                  "Would you like to keep your game history from previous seasons?"
                )}
              </strong>
            </p>
            <p>
              {t(
                "stitchBox.canStitch.p2",
                "To do so, you'll need to merge your old account with the new one you are now using."
              )}
            </p>
            <p>
              {t(
                "stitchBox.canStitch.p3",
                "This should only take a couple of minutes. It can be done at any time, even once the season is under way."
              )}
            </p>
            <Button variant="secondary" to="merge-email" as={Link}>
              {t("stitchBox.canStitch.button", "Merge account")}
            </Button>
          </Copy>
        </Alert>
      </Box>
    );
  }

  if (isConfirmStitch) {
    return (
      <Box mb={4}>
        <Alert type="warning">
          <Copy>
            <p>
              {t(
                "stitchBox.confirmStitch.p1",
                "Your account has now been merged."
              )}
            </p>
            <p>
              <strong>
                {t(
                  "stitchBox.confirmStitch.p2",
                  "You need to reconfirm your details below before you can continue"
                )}
              </strong>
              .
            </p>
          </Copy>
        </Alert>
      </Box>
    );
  }

  if (isStitched) {
    return (
      <Box mb={4}>
        <Alert type="success">
          <Copy>
            <p>
              {t(
                "stitchBox.stitched.p1",
                "Your new account has been merged with your old account"
              )}
            </p>
          </Copy>
        </Alert>
      </Box>
    );
  }

  return null;
};

export default StitchBox;
