import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  cancelProposedChip,
  proposeAvailableChip,
} from "../../core/store/chips/actions";
import {
  getActiveChipName,
  getAvailableChipNames,
  getProposedChipName,
} from "../../core/store/chips/reducers";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { ITransferState } from "../../core/store/my-team/types";
import {
  getFreeTransfers,
  getProposedTransfers,
  getTransferCosts,
  getTransferState,
} from "../../core/store/squad/reducers";
import { makeTransfers } from "../../core/store/squad/thunks";
import { ITransfersData } from "../../core/store/squad/types";
import { formatRawAsLocalI18n } from "../../core/utils/datetime";
import { dateLocales } from "../../i18n";
import Alert, { AlertGroup, AlertItem } from "../Alert";
import Button from "../Button";
import Dialog, { DialogButtonItem } from "../Dialog";
import DialogHeading from "../DialogHeading";
import Table, { THeadRadius } from "../Table";
import Panel from "../Panel";

interface IOwnProps {
  handleHide: () => void;
}

interface IPropsFromState {
  activeChip: string;
  elementsById: IElementsById;
  freeTransfers: number;
  nextEvent: IEvent | null;
  proposedChip: string;
  transferCosts: number;
  transfers: ITransfersData[];
  transferState: ITransferState | null;
  wildcardAvailable: boolean;
  unlimitAvaliable: boolean;
}

interface IPropsFromDispatch {
  makeTransfers: () => void;
  cancelChip: (chip: string) => void;
  proposeChip: (chip: string) => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch;

const ConfirmTransfersDialog = ({
  freeTransfers,
  handleHide,
  elementsById,
  makeTransfers,
  nextEvent,
  proposedChip,
  transferCosts,
  transfers,
  transferState,
  wildcardAvailable,
  unlimitAvaliable,
  cancelChip,
  proposeChip,
}: Props) => {
  const { i18n, t } = useTranslation();
  if (!nextEvent || !transferState) {
    return null;
  }
  return (
    <Dialog closeDialog={handleHide}>
      <Dialog.Header closeDialog={handleHide}>
        <Box py={2} px={3} bg="white">
          <DialogHeading>
            {t("confirmTransfers.heading", "Confirm Transfers")}
          </DialogHeading>
        </Box>
      </Dialog.Header>
      <Dialog.Body isPadded={true}>
        <Box mb={4}>
          <Panel>
            <THeadRadius>
              <Table>
                <thead>
                  <tr>
                    <th>{t("confirmTransfers.out", "Out")}</th>
                    <th>{t("confirmTransfers.in", "In")}</th>
                    <th>{t("confirmTransfers.cost", "Cose")}</th>
                  </tr>
                </thead>
                <tbody>
                  {transfers.map((t, index) => (
                    <tr key={t.element_out}>
                      <td>{elementsById[t.element_out].web_name}</td>
                      <td>{elementsById[t.element_in].web_name}</td>
                      <td>
                        {transferCosts && index >= freeTransfers
                          ? transferState.cost
                          : 0}{" "}
                        pts
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td />
                    <td>{t("confirmTransfers.totalCost", "Total cost")}</td>
                    <td>{transferCosts} pts</td>
                  </tr>
                </tbody>
              </Table>
            </THeadRadius>
          </Panel>
        </Box>
        <span className="notranslate">
          {transferCosts > 0 ? (
            <Alert type="warning">
              <AlertGroup>
                <AlertItem>
                  {t(
                    "confirmTransfers.transferAlert",
                    `
                  These transfers will be active for ${
                    nextEvent.name
                  } (${formatRawAsLocalI18n(
                      nextEvent.deadline_time,
                      dateLocales[i18n.language]
                    )}) and will deduct ${transferCosts}pts from your score!
                  `,
                    {
                      name: nextEvent.name,
                      time: formatRawAsLocalI18n(
                        nextEvent.deadline_time,
                        dateLocales[i18n.language]
                      ),
                      costs: transferCosts,
                    }
                  )}
                </AlertItem>
              </AlertGroup>
              {wildcardAvailable && proposedChip !== "uteam" && (
                <AlertGroup>
                  <AlertItem>
                    {t(
                      "confirmTransfers.playYourWildcard",
                      "Play your wildcard to make unlimited free transfers this Round."
                    )}
                  </AlertItem>
                  <Button onClick={() => proposeChip("wildcard")} width={1}>
                    {t("confirmTransfers.playWildcard", `Play Wildcard`)}
                  </Button>
                </AlertGroup>
              )}
            </Alert>
          ) : (
            <Alert type="warning">
              <AlertGroup>
                <AlertItem>
                  {t(
                    "confirmTransfers.theseTransfersWill",
                    `These transfers will be active for ${
                      nextEvent.name
                    } (${formatRawAsLocalI18n(
                      nextEvent.deadline_time,
                      dateLocales[i18n.language]
                    )})`,
                    {
                      name: nextEvent.name,
                      time: formatRawAsLocalI18n(
                        nextEvent.deadline_time,
                        dateLocales[i18n.language]
                      ),
                    }
                  )}
                </AlertItem>
              </AlertGroup>
              {proposedChip === "wildcard" && (
                <AlertGroup>
                  <AlertItem>
                    <strong>
                      {t(
                        "confirmTransfers.youAreProposingWildcard",
                        "You are proposing to play your wildcard!"
                      )}
                    </strong>
                  </AlertItem>
                  <AlertItem>
                    {t(
                      "confirmTransfers.cancelYourWildcard",
                      "Cancel your wildcard if you would prefer to spend points on these transfers."
                    )}
                  </AlertItem>
                  <Button onClick={() => cancelChip("wildcard")} width={1}>
                    {t("confirmTransfers.cancelWildcard", "Cancel Wildcard")}
                  </Button>
                </AlertGroup>
              )}
              {proposedChip === "uteam" && (
                <AlertGroup>
                  <AlertItem>
                    <b>
                      {t(
                        "confirmTransfers.proposingUnlimit",
                        "You are proposing to play your Loan Rangers chip! This allows you to make unlimited free transfers and choose how many players you want from a specific team for a single round. At the next deadline, your squad is returned to how it was at the start of the round."
                      )}
                    </b>
                  </AlertItem>
                  <AlertItem>
                    {t(
                      "confirmTransfers.cancelYourUnlimit",
                      "Cancel Loan Rangers chip if you would prefer not to do this."
                    )}
                  </AlertItem>
                  <Button
                    onClick={() => {
                      cancelChip("uteam");
                      return handleHide();
                    }}
                    width={1}
                  >
                    {t("confirmTransfers.cancelUnlimit", "Cancel Loan Rangers")}
                  </Button>
                </AlertGroup>
              )}
            </Alert>
          )}
          <Box mt={4}>
            <div>
              <DialogButtonItem>
                <Button onClick={makeTransfers} width={1}>
                  {t("confirmTransfers.confirmTransfers", "Confirm Transfers")}

                  {transferCosts > 0 && !proposedChip && (
                    <>
                      <br />
                      (-{transferCosts}pts)
                    </>
                  )}
                  {proposedChip === "wildcard" && (
                    <>
                      <br />(
                      {t(
                        "confirmTransfers.andPlayWildcard",
                        "and play Wildcard"
                      )}
                      )
                    </>
                  )}
                  {proposedChip === "uteam" && (
                    <>
                      <br />(
                      {t(
                        "confirmTransfers.andPlayUnlimit",
                        "and play Loan Rangers"
                      )}
                      )
                    </>
                  )}
                </Button>
              </DialogButtonItem>
              <DialogButtonItem>
                <Button variant="secondary" onClick={handleHide} width={1}>
                  {t("confirmTransfers.changeTransfers", "Change Transfers")}
                </Button>
              </DialogButtonItem>
            </div>
          </Box>
        </span>
      </Dialog.Body>
    </Dialog>
  );
};

export { ConfirmTransfersDialog as ConfirmTransfersDialogTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const availableChips = getAvailableChipNames(state);
  return {
    activeChip: getActiveChipName(state),
    elementsById: getElementsById(state),
    freeTransfers: getFreeTransfers(state),
    nextEvent: getNextEvent(state),
    proposedChip: getProposedChipName(state),
    transferCosts: getTransferCosts(state),
    transferState: getTransferState(state),
    transfers: getProposedTransfers(state),
    wildcardAvailable: availableChips.indexOf("wildcard") > -1,
    unlimitAvaliable: availableChips.indexOf("uteam") > -1,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  makeTransfers: () => dispatch(makeTransfers()),
  cancelChip: (chip: string) => dispatch(cancelProposedChip(chip)),
  proposeChip: (chip: string) => dispatch(proposeAvailableChip(chip)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmTransfersDialog);
