import { navigate, RouteComponentProps } from "@reach/router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import Button from "../Button";
import Copy from "../Copy";
import { InputField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import { FetchPost } from "./utils";

const MergeEmail: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    FetchPost("player/otp-send/", { email }).then(() =>
      navigate("./merge-code")
    );
  };

  return (
    <Wrapper>
      <Main>
        <Copy>
          <Title>{t("mergeEmail.title", "Merge Account")}</Title>
          <SubHeading>{t("mergeEmail.subheading", "Send Code")}</SubHeading>
          <p>
            {t(
              "mergeEmail.intro",
              "To merge your account, we need to send a code to the email address registered to your old Allsvenskan Fantasy account. Please enter the email address you used to log into your old Allsvenskan Fantasy account (it may be the same one you use for your new account)."
            )}
          </p>
          <form onSubmit={handleSubmit}>
            <InputField
              type="email"
              id="email"
              label={t("mergeEmail.label", "Email")}
              value={email}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setEmail(e.currentTarget.value)
              }
              required={false}
            />
            <Box mt={2} mb={4}>
              <Button type="submit" disabled={!email}>
                {t("mergeEmail.button", "Send code")}
              </Button>
            </Box>
          </form>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export default MergeEmail;
