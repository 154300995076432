import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import { IElementsById } from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import ElementDialogButton from "../ElementDialogButton";
import ElementShirt from "../ElementShirt";
import { Controls } from "../Pitch";
import { UnstyledButton } from "../Utils";
import StatusPitchElementData from "./StatusPitchElementData";

export const StatusPitchElementWrap = styled.div`
  margin: 0 0.2rem;
  padding: 0.4rem 0;
  border-radius: 0.4rem;
  align-items: stretch;
`;

export const StyledStatusPitchElement = styled.div`
  position: relative;
  width: 6rem;
  margin: auto;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 7rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 10.5rem;
  }
`;

const StyledInfoControl = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: absolute;
    top: 0;
    right: 5px;
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
}

interface IOwnProps {
  pick: IPickLight;
  renderElementMenu: () => void;
  renderPickValue: (pick: IPickLight) => React.ReactNode;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps;

const Squad: React.FC<Props> = ({
  pick,
  elementsById,
  renderElementMenu,
  renderPickValue,
}) => {
  const element = elementsById[pick.element];

  return (
    <StatusPitchElementWrap>
      <StyledStatusPitchElement>
        <UnstyledButton type="button" onClick={renderElementMenu}>
          <ElementShirt
            elementId={element.id}
            hasShadow={true}
            sizes="(min-width: 1024px) 44px, 33px"
          />
          <StatusPitchElementData
            element={element}
            value={renderPickValue(pick)}
          />
        </UnstyledButton>
        <Controls>
          <StyledInfoControl>
            <ElementDialogButton elementId={pick.element} />
          </StyledInfoControl>
        </Controls>
      </StyledStatusPitchElement>
    </StatusPitchElementWrap>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Squad);
