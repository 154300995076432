import { Link } from "@reach/router";
import React from "react";
import styled, { css } from "styled-components/macro";

const NavList = styled.ul`
  margin: ${({ theme }) => theme.space[2]};
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 3px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-right: 20%;
    margin-left: 20%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    margin-right: 30%;
    margin-left: 30%;
  }
`;

const NavItem = styled.li`
  flex: 1;
  :not(:last-child) {
    border-right: 2px solid white;
  }
`;

// TODO - This stops isCurrent being passed to Link but doesn't have
// any type checking
const NavLink = styled(({ isCurrent, ...rest }) => <Link {...rest} />)`
  display: block;
  padding: ${({ theme }) => theme.space[2]};
  color: white;
  font-family: ${({ theme }) => theme.fonts.bold};
  text-decoration: none;
  text-align: center;

  ${(props) =>
    props.isCurrent &&
    css`
      background-color: white;
      color: ${({ theme }) => theme.colors.black};
    `}
`;

interface IProps {
  defaultEntry?: number;
  leagueId: number;
  eventId: number;
}

const H2HNav: React.FC<IProps> = ({ eventId, leagueId, defaultEntry }) => {
  const defaultEntryParam = defaultEntry ? `default_entry=${defaultEntry}` : "";
  const links = [
    {
      href: `/leagues/${leagueId}/matches/h`,
      qs: `?event=${eventId}` + (defaultEntryParam && `&${defaultEntryParam}`),
      text: "Matches",
    },
    {
      href: `/leagues/${leagueId}/standings/h`,
      qs: defaultEntryParam && `?${defaultEntryParam}`,
      text: "Standings",
    },
  ];
  return (
    <nav>
      <NavList>
        {links.map((l) => (
          <NavItem key={l.href}>
            <NavLink
              to={`${l.href}${l.qs}`}
              isCurrent={window.location.pathname === l.href}
            >
              {l.text}
            </NavLink>
          </NavItem>
        ))}
      </NavList>
    </nav>
  );
};

export default H2HNav;
