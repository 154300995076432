import React from "react";
import styled, { css } from "styled-components";
import Button from "./Button";

interface SProps {
  lgSrc?: string;
  mdSrc: string;
  smSrc: string;
  reverse?: boolean;
}

interface SVGProps {
  reverse?: boolean;
  highlight?: boolean;
}

const Title = styled.h3`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.impact};
  margin-bottom: ${({ theme }) => theme.space[1]};
  text-transform: uppercase;
`;

const Description = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledTriangleCard = styled.div<SVGProps>`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 230px;
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.primary};

  ${({ reverse }) =>
    reverse === true &&
    css`
      flex-direction: row-reverse;
    `}

  ${({ highlight }) =>
    highlight === true &&
    css`
      min-height: 320px;
      background-color: #005b6d;

      ${Title} {
        font-size: 26px;
        line-height: 32px;
      }
    `}
`;

const BtnWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 40%;
  }
`;

const BgImg = styled.div<SProps>`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: relative;
    min-height: 230px;
    width: 50%;
    display: flex;
    align-items: center;
    border-top-right-radius: ${({ theme }) => theme.radii[2]};
    border-bottom-right-radius: ${({ theme }) => theme.radii[2]};
    background-color: ${({ theme }) => theme.colors.grey};
    background-image: url(${({ smSrc }) => smSrc});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    background-image: url(${({ mdSrc }) => mdSrc});
  }
`;

const Content = styled.div<SVGProps>`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 50%;
    border-right: 3px solid ${({ theme }) => theme.colors.yellow};
  }
`;

const ContentInner = styled.div<SVGProps>`
  position: relative;
  z-index: 10;
  padding: 2rem 3rem;
  color: ${({ theme }) => theme.colors.black};

  ${({ highlight }) =>
    highlight === true &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  button {
    margin-top: ${({ theme }) => theme.space[1]};
  }
`;

interface IProps {
  title: string;
  desc: string;
  lgSrc?: string;
  mdSrc: string;
  smSrc: string;
  btnText?: string;
  btnLink?: string;
  reverse?: boolean;
  highlight?: boolean;
}

const TriangleCard = ({
  lgSrc,
  mdSrc,
  smSrc,
  title,
  desc,
  btnText,
  btnLink,
  reverse,
  highlight,
}: IProps) => (
  <StyledTriangleCard reverse={reverse} highlight={highlight}>
    <Content reverse={reverse} highlight={highlight}>
      <ContentInner highlight={highlight}>
        <Title>{title}</Title>
        <Description>{desc}</Description>
        {btnText && (
          <BtnWrapper>
            <Button width={1} newTab href={btnLink}>
              {btnText}
            </Button>
          </BtnWrapper>
        )}
      </ContentInner>
    </Content>
    <BgImg lgSrc={lgSrc} mdSrc={mdSrc} smSrc={smSrc} reverse={reverse} />
  </StyledTriangleCard>
);

export default TriangleCard;
