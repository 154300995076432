import React from "react";
import styled, { css } from "styled-components/macro";
import { hiDPI } from "polished";

const StyledHero = styled.div<IStyledProps>`
  background-image: linear-gradient(
    -45deg,
    #0f253f,
    #0f253f 50%,
    ${({ theme }) => theme.colors.blue} 44%
  );
  margin-bottom: 30px;
  padding-top: 270px;
  border-radius: ${({ theme }) => theme.radii[2]};
  text-align: center;
  height: 0;
  position: relative;
  background-repeat: no-repeat;

  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-size: cover;
    `}

  ${(props) =>
    props.image2x &&
    css`
      ${hiDPI(2)} {
        background-image: url(${props.image2x});
      }
    `}
`;

const HeroTitle = styled.h2`
  display: inline-block;
  margin: 0 ${({ theme }) => theme.space[2]};
  padding: 3px ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 34px;
  font-weight: 700;
  text-transform: uppercase;
  background: white;
  transform: translateY(-50%);
  line-height: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 48px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 60px;
  }
`;

interface IProps {
  title?: string;
}

interface IStyledProps {
  image?: string;
  image2x?: string;
}

type Props = IProps & IStyledProps;

const Hero: React.FC<Props> = ({ image, image2x, title }) => (
  <StyledHero image={image} image2x={image2x}>
    {title && <HeroTitle>{title}</HeroTitle>}
  </StyledHero>
);

export default Hero;
