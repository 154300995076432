import React from "react";
import { Link } from "@reach/router";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState } from "../../core/store";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import Copy from "../Copy";
import Button from "../Button";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import { ControlArrowRight } from "../icons/Arrows";
import { useTranslation } from "react-i18next";

interface IPropsFromState {
  settings: ISettings | null;
}

const Join: React.FC<IPropsFromState> = ({ settings }) => {
  const { t } = useTranslation();
  if (!settings) {
    return null;
  }
  return (
    <Wrapper>
      <Main>
        <Copy>
          <Title>{t("join.title", "Choose a league type to join")}</Title>
          <p>
            {t(
              "join.p1",
              "You can join up to {{ maxPrivateLeagues }} private leagues and {{ maxPublicLeagues }} public leagues.",
              {
                maxPrivateLeagues: settings.league_join_private_max,
                maxPublicLeagues: settings.league_join_public_max,
              }
            )}
          </p>
          <Box mb={4}>
            <SubHeading>
              {t("join.private.title", "Private leagues & cups")}
            </SubHeading>
            <p>
              {t(
                "join.private.p1",
                "Join a private league if somebody has given you a league code to enter."
              )}
            </p>

            <Button as={Link} endIcon={<ControlArrowRight />} to="private">
              {t("join.private.button", "Join private league & cup")}
            </Button>
          </Box>
          <SubHeading>{t("join.public.title", "Public leagues")}</SubHeading>
          <p>
            {t(
              "join.public.p1",
              "Join a public league to play against a small, randomly selected group of other game players."
            )}
          </p>
          <Button as={Link} endIcon={<ControlArrowRight />} to="public">
            {t("join.public.button", "Join public league")}
          </Button>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export { Join as JoinTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  settings: getSettings(state),
});

export default connect(mapStateToProps)(Join);
