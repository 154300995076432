import React from "react";
import styled from "styled-components";
interface ILogoProps {
  justifyContent: string;
}

const StyledLogoBar = styled.div<ILogoProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
`;

interface IProps {
  children: React.ReactNode;
  justifyContent?: string;
}

const LogoBar: React.FC<IProps> = ({
  children,
  justifyContent = "center",
  ...props
}) => {
  return (
    <StyledLogoBar justifyContent={justifyContent} {...props}>
      {children}
    </StyledLogoBar>
  );
};

export default LogoBar;
