import { RouteComponentProps } from "@reach/router";
import { hiDPI } from "polished";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getCurrentEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
// Hero images
import heroImg1x from "../img/hero-image-2023.jpg";
import heroImg2x from "../img/hero-image-2023@2x.jpg";
// Highlight images
import celebration from "../img/home/celebration-2023.jpg";
import celebration2x from "../img/home/celebration-2023@2x.jpg";
import myTeam from "../img/home/myteam-2021.png";
import myTeam2x from "../img/home/myteam-2021@2x.png";
// import pitch from "../img/home/pitch.jpg";
// import pitch2x from "../img/home/pitch@2x.jpg";
import { Main, Wrapper } from "./Layout";
import Login from "./Login";
import Status from "./status/Status";
import TriangleCard from "./TriangleCard";

const HomeHero = styled.div`
  margin-left: ${({ theme }) => theme.space[2]};
  margin-right: ${({ theme }) => theme.space[2]};
  margin-bottom: 60px;
  padding-top: 270px;
  text-align: center;
  height: 0;
  position: relative;
  border-radius: ${({ theme }) => theme.radii[2]};
  background-image: url(${heroImg1x});
  background-size: cover;
  background-repeat: no-repeat;

  ${hiDPI(2)} {
    background-image: url(${heroImg2x});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Highlights = styled.div`
  margin: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const HighlightsItem = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};
  border-radius: ${({ theme }) => theme.radii[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 0 0 calc(50% - 1rem);
    margin-bottom: 0;
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  player: IPlayer | null;
}

type Props = RouteComponentProps & IPropsFromState & WithTranslation;

class Home extends React.Component<Props> {
  public componentDidMount() {
    this.TrackFacebook();
  }

  public TrackFacebook() {
    const fbq = (window as any).fbq;
    if (fbq) {
      fbq("trackCustom", "FPLLanding");
    }
  }

  public render() {
    const { t, now, player } = this.props;

    // Show the status page if have a team and the game has started
    if (now && player && player.entry) {
      return <Status />;
    }

    return (
      <Wrapper>
        <Main isWide={true}>
          <HomeHero />
          <Login />
          <Highlights>
            <HighlightsItem>
              <TriangleCard
                title={t("home.highlightsLeftTitle", "Pick your squad")}
                desc={t(
                  "home.highlightsLeftDesc",
                  "Use your budget of 100Mkr to pick a squad of 15 players from Allsvenskan."
                )}
                mdSrc={myTeam2x}
                smSrc={myTeam}
              />
            </HighlightsItem>
            <HighlightsItem>
              <TriangleCard
                title={t("home.highlightsRightTitle", "Create & join leagues")}
                desc={t(
                  "home.highlightsRightDesc",
                  "Play against friends, family members, colleagues or your friends from the web in private leagues."
                )}
                mdSrc={celebration2x}
                smSrc={celebration}
              />
            </HighlightsItem>
          </Highlights>
          {/* <Highlights>
            <TriangleCard
              reverse
              highlight
              title={t("home.highlightBottomTitle", "That's How Fantasy Works")}
              desc={t(
                "home.highlightBottomDesc",
                "In Allsvenskan Fantasy, it is not just about punching in the right starting eleven, you need to keep track of your formation, bench and maybe above all the budget. This is how it works!"
              )}
              mdSrc={pitch2x}
              smSrc={pitch}
              btnText={t("home.highlightBottomBtn", "Read more")}
              btnLink="https://www.allsvenskan.se/fantasy/sa-valjer-du-din-din-trupp"
            />
          </Highlights> */}
        </Main>
      </Wrapper>
    );
  }
}

export { Home as HomeTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  now: getCurrentEvent(state),
  player: getPlayerData(state),
});

export default withTranslation()(connect(mapStateToProps)(Home));
