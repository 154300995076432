import { TFunction } from "i18next";

export type ChipName = "frush" | "2capt" | "pdbus" | "wildcard" | "uteam";

interface IChipDetails {
  description: string;
  name: string;
}

const allChipDetails = (t: TFunction): Record<ChipName, IChipDetails> => ({
  frush: {
    name: t("chips.frush.name", "Attack! Attack!"),
    description: t(
      "chips.frush.description",
      "All forwards get double points."
    ),
  },
  "2capt": {
    name: t("chips.2capt.name", "Dynamic duo"),
    description: t(
      "chips.2capt.description",
      "Both your Captain and Vice Captain score triple points."
    ),
  },
  pdbus: {
    name: t("chips.pdbus.name", "Park The Bus"),
    description: t(
      "chips.pdbus.description",
      "Double points for all defenders"
    ),
  },
  wildcard: {
    name: t("chips.wildcard.name", "Wildcard"),
    description: t(
      "chips.wildcard.description",
      "Make unlimited free transfers."
    ),
  },
  uteam: {
    name: t("chips.unlimit.name", "Loan Rangers"),
    description: t(
      "chips.unlimit.description",
      "Make unlimited free transfers and choose how many players you want from a specific team for a single round. At the next deadline your squad is returned to how it was at the start of the Round."
    ),
  },
});

export const getChipName = (name: ChipName, t: TFunction) =>
  allChipDetails(t)[name].name;

export const getChipDetails = (name: ChipName, t: TFunction) =>
  allChipDetails(t)[name];

export const getAllChipDetails = (t: TFunction) => allChipDetails(t);
