import { createGlobalStyle } from "styled-components/macro";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  html {
		font-family: ${theme.fonts.base};
		font-size: 62.5%;
		line-height: normal;
		-webkit-font-smoothing: antialiased;
  }

  body {
  	margin: 0;
	background-color: ${theme.colors.blueDarker3};
	color: ${theme.colors.white};
    font-size: ${theme.fontSizes[2]};
 }

	.ism {
		box-sizing: border-box;
		font-family: ${theme.fonts.base};

		*, *::before, *::after {
			box-sizing: inherit;
		}

		p {
			line-height: 1.6;
			font-size: 14px;
		}

		input[type="search"] {
			box-sizing: inherit;
		}
	}
`;

export default GlobalStyle;
