import { Link as ReachLink, RouteComponentProps } from "@reach/router";
import range from "lodash/range";
import { hideVisually, size } from "polished";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getElementsById,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import { fetchEventLive } from "../../core/store/elements/thunks";
import {
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import {
  getEntry,
  getEntryEventFormation,
  getEntryEventPicks,
  getEntryEventPoints,
} from "../../core/store/entries/reducers";
import {
  fetchEntryEventPicks,
  fetchEntrySummary,
} from "../../core/store/entries/thunks";
import {
  IEntry,
  IEntryEvent,
  IPickLight,
} from "../../core/store/entries/types";
import {
  getCurrentEvent,
  getEventsById,
} from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getFixturesForEventById } from "../../core/store/fixtures/reducers";
import { fetchFixtures } from "../../core/store/fixtures/thunks";
import { IFixture } from "../../core/store/fixtures/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { ReactComponent as BaseDreamTeam } from "../../img/icons/dreamteam.svg";
import { ReactComponent as BaseReload } from "../../img/icons/reload.svg";
import { ChipName, getChipName } from "../../utils/chips";
import ButtonLink from "../ButtonLink";
import Entry from "../Entry";
import Fixtures from "../Fixtures";
import { ControlArrowLeft, ControlArrowRight } from "../icons/Arrows";
import DreamTeam from "../icons/DreamTeam";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import {
  EventPager,
  PagerButton,
  PagerButtonNext,
  PagerHeading,
} from "../Pager";
import Panel from "../Panel";
import { ElementRow } from "../Pitch";
import { NotFound } from "../Routes";
import Table, { THeadRadius } from "../Table";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import Title from "../Title";
import Bench from "./Bench";
import BenchUnit from "./BenchUnit";
import ElementExplainDialog from "./ElementExplainDialog";
import EntryEventTable from "./EntryEventTable";
import PitchFormation from "./PitchFormation";
import valueForPlayerElement from "./valueForPlayedElement";

const StyledPitchWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    border-radius: ${({ theme }) => theme.radii[2]};
  }
`;

const PointsScoreboard = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    position: relative;
    align-items: flex-start;
    padding-bottom: 4rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    border-radius: ${({ theme }) => theme.radii[2]};
  }
`;

const ScoreboardPrimary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-basis: 25%;
  }
`;

interface IPrimaryPanelProps {
  chipPlayed?: boolean;
}

const PrimaryPanel = styled.div<IPrimaryPanelProps>`
  padding-top: 4rem;
  padding-right: ${({ theme }) => theme.space[2]};
  padding-bottom: 4.3rem;
  padding-left: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.blueDarker3};
  text-align: center;

  ${(props) =>
    props.chipPlayed &&
    css`
      padding-top: 2rem;
      padding-bottom: 1.8rem;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 1.2rem 0;
  }
`;

const PrimaryHeading = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  margin: ${({ theme }) => theme.space[2]} 0;
`;

const PrimaryValue = styled.div`
  position: relative;
  font-size: 4rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-bottom: 0.3rem;
  }
`;

const ReloadButton = styled.button`
  position: absolute;
  top: 50%;
  left: -${({ theme }) => theme.space[2]};
  transform: translateY(-50%);
  padding: ${({ theme }) => theme.space[1]};
  border: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  line-height: 1;
  cursor: pointer;
`;

const Reload = styled(BaseReload)`
  fill: white;
`;

const ReloadButtonText = styled.span`
  ${hideVisually()}
`;

const ScoreboardSecondary = styled.div`
  flex: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    align-items: flex-start;
    flex-basis: 75%;
  }
`;

const SecondaryPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1;

    :not(:last-child) {
      margin-right: ${({ theme }) => theme.space[2]};
    }
  }
`;

const SecondaryItem = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.blueDarker3};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
  }
`;

const SecondaryHeading = styled.h4`
  flex: 1 0 60%;
  margin-top: 1.7rem;
  padding-left: ${({ theme }) => theme.space[2]};
  margin-bottom: 1.7rem;
`;

const SecondaryValue = styled.div`
  flex: 1 0 40%;
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
  padding-right: ${({ theme }) => theme.space[2]};
  font-weight: bold;
  text-align: right;
`;

const DreamTeamLinkIcon = styled(BaseDreamTeam)`
  ${size(16)}
  margin-right: ${({ theme }) => theme.space[1]};
  color: white;
  fill: #005baa;
`;

const DreamTeamLink = styled(ReachLink)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: ${({ theme }) => theme.borders[0]};
  border-radius: ${({ theme }) => theme.radii[1]};
  padding: 0.8rem;
  background-color: white;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    position: absolute;
    right: ${({ theme }) => theme.space[2]};
    bottom: ${({ theme }) => theme.space[2]};
  }
`;

const ChipStatus = styled.div`
  margin-top: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.success};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: bold;
  text-align: center;
`;

type OwnProps = RouteComponentProps<{ entryId: string; eventId: string }> &
  WithTranslation;

interface IPropsFromState {
  elementsById: IElementsById;
  elementsDataById: IElementsEventDataById;
  entry: IEntry | null;
  entryData: IEntryEvent | null;
  event: IEvent | null;
  fixturesById: Record<string, IFixture> | null;
  formation: string;
  mine: boolean;
  now: IEvent | null;
  points: number;
  settings: ISettings;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  fetchEntryEventPicks: (entryId: number, eventId: number) => void;
  fetchEntrySummary: (entryId: number) => void;
  fetchEventLive: (entryId: number) => void;
  fetchFixturesForEvent: (eventId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  pickForMenu: IPickLight | null;
}

class EntryEvent extends React.Component<Props, IState> {
  public state: IState = {
    pickForMenu: null,
  };

  public handleShowMenuForPickElement = (element: number) => {
    const matches = this.props.entryData!.picks.filter(
      (p) => p.element === element
    );
    if (matches.length) {
      this.setState({ pickForMenu: matches[0] });
    }
  };

  public handleHideMenuForPick = () => {
    this.setState({ pickForMenu: null });
  };

  public renderDreamTeam = (pick: IPickLight) =>
    this.props.elementsDataById &&
    this.props.elementsDataById[pick.element] &&
    this.props.elementsDataById[pick.element].stats.in_dreamteam ? (
      <Link to={`/dream-team/${this.props.event!.id}`}>
        <DreamTeam />
      </Link>
    ) : null;

  public fetchDataAndFixtures = () => {
    this.fetchData();
    this.props.fetchFixturesForEvent(Number(this.props.eventId));
  };

  public fetchData = () => {
    this.props.fetchEntrySummary(Number(this.props.entryId));
    this.props.fetchEntryEventPicks(
      Number(this.props.entryId),
      Number(this.props.eventId)
    );
    this.props.fetchEventLive(Number(this.props.eventId));
  };

  public componentDidMount() {
    this.fetchData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.eventId !== this.props.eventId ||
      prevProps.entryId !== this.props.entryId
    ) {
      this.fetchData();
    }
  }
  public render() {
    const {
      elementsById,
      elementsDataById,
      entry,
      entryData,
      event,
      fixturesById,
      formation,
      mine,
      now,
      points,
      settings,
      t,
      teamsById,
    } = this.props;
    // Handle unknown and unstarted events
    if (!now || !event || event.id > now.id) {
      return <NotFound />;
    }
    if (!entry || !entryData) {
      return null;
    }

    const chipName = entryData.active_chip
      ? getChipName(entryData.active_chip as ChipName, t)
      : "";

    // Create a new function on each render as data could have changed and
    // need to ensure a render of connected subcomponents
    const renderPickValue = valueForPlayerElement({
      elementsById,
      elementsDataById,
      fixturesById,
      teamsById,
    });
    const benchHeading: Record<string, React.ReactNode> = {
      11: (
        <abbr title={t("entryEvent.goalkeeper.title", "Goalkeeper")}>
          {t("entryEvent.goalkeeper.abbr", "GK")}
        </abbr>
      ),
      12: "1",
      13: "2",
      14: "3",
    };

    // Handle events before the entry started
    return (
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>
              {t("entryEvent.title", "Points")} - {entry.name}
            </Title>
          </Box>
          {/* ROUND PAGER */}
          <Box mb={4}>
            <Box mx={2}>
              <PagerHeading>{event.name}</PagerHeading>
            </Box>
            {(event.id > entry.started_event || event.id < now.id) && (
              <EventPager>
                {event.id > entry.started_event && (
                  <PagerButton>
                    <ButtonLink
                      to={`/entry/${entry.id}/event/${event.id - 1}`}
                      variant="primary"
                    >
                      <ControlArrowLeft />
                      {t("entryEvent.prev", "Previous")}
                    </ButtonLink>
                  </PagerButton>
                )}
                {event.id < now.id && (
                  <PagerButtonNext>
                    <ButtonLink
                      to={`/entry/${entry.id}/event/${event.id + 1}`}
                      variant="primary"
                    >
                      {t("entryEvent.next", "Next")}
                      <ControlArrowRight />
                    </ButtonLink>
                  </PagerButtonNext>
                )}
                {/* Also need to check isn't last event */}
                {mine && event.id === now.id && (
                  <PagerButtonNext>
                    <ButtonLink to={`/my-team`} variant="primary">
                      {t("entryEvent.next", "Next")}
                      <ControlArrowRight />
                    </ButtonLink>
                  </PagerButtonNext>
                )}
              </EventPager>
            )}
          </Box>
          {/* SCOREBOARD */}
          <Box mb={4}>
            <PointsScoreboard>
              <ScoreboardPrimary>
                <Box mb={2}>
                  <PrimaryPanel chipPlayed={Boolean(chipName)}>
                    <PrimaryHeading>
                      {event.finished
                        ? t("entryEvent.finalPoints", "Final Points")
                        : t("entryEvent.latestPoints", "Latest Points")}
                    </PrimaryHeading>
                    <PrimaryValue>
                      {points}
                      {!event.finished && (
                        <ReloadButton onClick={this.fetchDataAndFixtures}>
                          <ReloadButtonText>
                            {t("entryEvent.reload", "Reload Points")}
                          </ReloadButtonText>
                          <Reload />
                        </ReloadButton>
                      )}
                    </PrimaryValue>
                  </PrimaryPanel>
                  {chipName && (
                    <ChipStatus>
                      {t(
                        "entryEvent.ChipStatus.played",
                        "{{ chipName }} played",
                        {
                          chipName: chipName,
                        }
                      )}
                    </ChipStatus>
                  )}
                </Box>
                <div>
                  <DreamTeamLink to={`/dream-team/${event.id}`}>
                    <DreamTeamLinkIcon />
                    {t("entryEvent.dreamTeam", "Dream Team")}
                    <ControlArrowRight />
                  </DreamTeamLink>
                </div>
              </ScoreboardPrimary>
              <ScoreboardSecondary>
                <SecondaryPanel>
                  <SecondaryItem>
                    <SecondaryHeading>
                      {t("entryEvent.average", "Average Pts")}
                    </SecondaryHeading>
                    <SecondaryValue>
                      {event.average_entry_score || "-"}
                    </SecondaryValue>
                  </SecondaryItem>
                  <SecondaryItem>
                    <SecondaryHeading>
                      {t("entryEvent.highest", "Highest Pts")}
                    </SecondaryHeading>
                    <SecondaryValue>
                      {event.highest_scoring_entry ? (
                        <Link
                          to={`/entry/${event.highest_scoring_entry}/event/${event.id}`}
                        >
                          {event.highest_score || "???"}
                        </Link>
                      ) : (
                        "-"
                      )}
                    </SecondaryValue>
                  </SecondaryItem>
                </SecondaryPanel>
                <SecondaryPanel>
                  <SecondaryItem>
                    <SecondaryHeading>
                      {t("entryEvent.rank", "RD Rank")}
                    </SecondaryHeading>
                    <SecondaryValue>
                      {entryData.entry_history.rank
                        ? entryData.entry_history.rank.toLocaleString()
                        : "-"}
                    </SecondaryValue>
                  </SecondaryItem>
                  <SecondaryItem>
                    <SecondaryHeading>
                      {t("entryEvent.transfers", "Transfers")}
                    </SecondaryHeading>
                    <SecondaryValue>
                      <Link to={`/entry/${entry.id}/transfers`}>
                        {entryData.entry_history.event_transfers}
                      </Link>{" "}
                      {entryData.entry_history.event_transfers_cost
                        ? `(-${entryData.entry_history.event_transfers_cost} pts)`
                        : ""}
                    </SecondaryValue>
                  </SecondaryItem>
                </SecondaryPanel>
              </ScoreboardSecondary>
            </PointsScoreboard>
          </Box>
          {/* TEAM */}
          <StyledPitchWrapper>
            <Box pt={4}>
              <Box mx={2} mb={2}>
                <Tabs centered>
                  <TabPanel
                    label={t("entryEvent.pitchView", "Pitch View")}
                    link="pitch"
                  >
                    <Box mx={-2} pt={4}>
                      <PitchFormation
                        chipName={entryData.active_chip}
                        formation={formation}
                        picks={entryData.picks}
                        renderDreamTeam={this.renderDreamTeam}
                        renderElementMenu={this.handleShowMenuForPickElement}
                        renderPickValue={renderPickValue}
                      />
                      <Bench>
                        <ElementRow>
                          {range(
                            settings.squad_squadplay,
                            settings.squad_squadsize
                          ).map((i) => (
                            <BenchUnit
                              key={i}
                              chipName={entryData.active_chip}
                              heading={benchHeading[i]}
                              pick={entryData.picks[i]}
                              renderDreamTeam={this.renderDreamTeam}
                              renderElementMenu={() =>
                                this.handleShowMenuForPickElement(
                                  entryData.picks[i].element
                                )
                              }
                              renderPickValue={renderPickValue}
                            />
                          ))}
                        </ElementRow>
                      </Bench>
                    </Box>
                  </TabPanel>
                  <TabPanel
                    label={t("entryEvent.listView", "List View")}
                    link="list"
                  >
                    <Box my={4} bg="white">
                      <EntryEventTable
                        title={t("entryEvent.starters", "Starters")}
                        picks={entryData.picks.slice(
                          0,
                          settings.squad_squadplay
                        )}
                        renderElementMenu={this.handleShowMenuForPickElement}
                        chipName={entryData.active_chip}
                        dataById={elementsDataById}
                      />
                      <EntryEventTable
                        title={t("entryEvent.substitutes", "Substitutes")}
                        picks={entryData.picks.slice(settings.squad_squadplay)}
                        renderElementMenu={this.handleShowMenuForPickElement}
                        chipName={entryData.active_chip}
                        dataById={elementsDataById}
                      />
                    </Box>
                  </TabPanel>
                </Tabs>
              </Box>
            </Box>
          </StyledPitchWrapper>
          {entryData.automatic_subs.length > 0 && (
            <>
              <Box mx={2}>
                <Title>
                  {t("entryEvent.autoSubs.title", "Automatic Substitutions")}
                </Title>
              </Box>
              <Panel>
                <THeadRadius>
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          {t("entryEvent.autoSubs.playerOut", "Player out")}
                        </th>
                        <th>
                          {t("entryEvent.autoSubs.playerIn", "Player in")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {entryData.automatic_subs.map((s) => (
                        <tr key={s.element_out}>
                          <td>{elementsById[s.element_out].web_name}</td>
                          <td>{elementsById[s.element_in].web_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </THeadRadius>
              </Panel>
            </>
          )}
          {this.state.pickForMenu && (
            <ElementExplainDialog
              elementId={this.state.pickForMenu.element}
              eventId={event.id}
              closeDialog={this.handleHideMenuForPick}
            />
          )}
          <Fixtures eventId={event.id} />
        </Main>
        <Secondary>
          <Entry entryId={entry.id} />
        </Secondary>
      </Wrapper>
    );
  }
}
export { EntryEvent as EntryEventTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const entryId = Number(ownProps.entryId);
  const eventId = Number(ownProps.eventId);
  const player = getPlayerData(state) as IPlayer;
  return {
    elementsById: getElementsById(state),
    elementsDataById: getElementsEventDataById(state, eventId),
    entry: getEntry(state, entryId),
    event: getEventsById(state)[eventId],
    now: getCurrentEvent(state),
    entryData: getEntryEventPicks(state, entryId, eventId),
    fixturesById: getFixturesForEventById(state, eventId),
    formation: getEntryEventFormation(state, entryId, eventId),
    points: getEntryEventPoints(state, entryId, eventId),
    settings: getSettings(state) as ISettings,
    teamsById: getTeamsById(state),
    mine: Boolean(player && player.entry && player.entry === entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntryEventPicks: (entryId, eventId) =>
    dispatch(fetchEntryEventPicks(entryId, eventId)),
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchEventLive: (eventId) => dispatch(fetchEventLive(eventId)),
  fetchFixturesForEvent: (eventId) => dispatch(fetchFixtures(eventId)),
});

// Have to split declarations due to TS error - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/50211
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EntryEvent));
const translated = withTranslation()(connected);

export default translated;
