import * as React from "react";
import styled, { css } from "styled-components/macro";
import { IElement } from "../core/store/elements/types";
import { ITeam } from "../core/store/teams/types";
import { integerToMoney, isMoneyStat } from "../core/utils/money";
import ElementDialogButton from "./ElementDialogButton";
import ElementInTable from "./ElementInTable";
import { ElementCell, ElementRow } from "./ElementTable";

const StyledElementListRow = styled(ElementRow)<IStyledProps>`
  ${(props) =>
    props.isProposed &&
    css`
      td,
      button {
        color: ${({ theme }) => theme.colors.white};
      }

      img {
        opacity: 0.6;
      }
    `}
`;

const ElementListStatCell = styled.td`
  font-weight: bold;
`;

interface IStyledProps {
  isProposed: boolean;
}

interface IProps extends IStyledProps {
  currencyDivisor: number;
  element: IElement;
  renderElementDialog: () => void;
  renderElementMenu: () => void;
  sort: React.ReactText;
  team: ITeam;
}

const ElementListRow: React.FC<IProps> = ({
  currencyDivisor,
  element,
  isProposed,
  renderElementMenu,
  sort,
  team,
}) => (
  <StyledElementListRow isProposed={isProposed}>
    <td>
      <ElementDialogButton elementId={element.id} variant="list" />
    </td>
    <ElementCell>
      <ElementInTable
        renderElementMenu={renderElementMenu}
        element={element}
        team={team}
      />
    </ElementCell>
    <ElementListStatCell>
      {integerToMoney(element.now_cost, currencyDivisor)}
    </ElementListStatCell>
    <ElementListStatCell>
      {isMoneyStat(sort)
        ? integerToMoney(element[sort] as number, currencyDivisor)
        : element[sort]}
    </ElementListStatCell>
  </StyledElementListRow>
);

export default ElementListRow;
