import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getElementStats } from "../core/store/element-stats/reducers";
import { IElementStat } from "../core/store/element-stats/types";
import { getElementTypesById } from "../core/store/element-types/reducers";
import { IElementTypesById } from "../core/store/element-types/types";
import { elementDialogHide } from "../core/store/elements/actions";
import {
  getElement,
  getElementDialog,
  getFixturesWithBlanks,
  getHistory,
  getHistoryTotals,
  getSeasonHistory,
} from "../core/store/elements/reducers";
import {
  IElement,
  IElementFixture,
  IElementFixtureBlank,
  IElementHistory,
  IElementSeasonHistory,
} from "../core/store/elements/types";
import { getCurrentEvent, getNextEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getPlayerData, getWatched } from "../core/store/player/reducers";
import {
  addToWatchlist,
  removeFromWatchlist,
} from "../core/store/player/thunks";
import { IPlayer } from "../core/store/player/types";
import { proposeElement } from "../core/store/squad/thunks";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeamsById } from "../core/store/teams/types";
import Badge from "./Badge";
import { formatRawAsLocalI18n } from "../core/utils/datetime";
import { integerToMoney } from "../core/utils/money";
import { dateLocales } from "../i18n";
import Alert from "./Alert";
import Button from "./Button";
import Dialog from "./Dialog";
import DialogHeading from "./DialogHeading";
import { getElementShirtPath } from "./ElementShirt";
import FixtureForElement from "./FixtureForElement";
import "./OverflowScrolling.css";
import Panel from "./Panel";
import SubHeading from "./SubHeading";
import Table from "./Table";
import TabPanel from "./tabs/TabPanel";
import Tabs from "./tabs/Tabs";
import Tooltip, { TooltipLabel } from "./Tooltip";
import { VisuallyHidden } from "./Utils";
import { DrawPrefix, LosePrefix, WinPrefix } from "./ScorePrefix";

const Summary = styled.div`
  padding: ${({ theme }) => theme.space[3]};
`;

const Card = styled.div`
  padding: 2px;
  background-color: ${({ theme }) => theme.colors.blueDarker3};
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
  }
`;

const PlayerWrapper = styled.div`
  order: 0;
  width: 50%;
  padding: 30px;
  box-sizing: border-box;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: auto;
    padding: ${({ theme }) => theme.space[3]};
  }
`;

const BadgeWrapper = styled.div`
  width: 50%;
  order: 1;
  padding: 30px;
  box-sizing: border-box;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    order: 3;
    width: auto;
    padding: ${({ theme }) => theme.space[3]};
  }
`;

const BadgeScoreFlex = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};
  padding-left: -5px;
`;

const EiwBadgeWrapper = styled.div`
  max-width: 25px;
  max-height: 25px;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[1]}
    ${theme.space[1]} 0`};
`;

const CardInfo = styled.div`
  order: 3;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 0 auto;
    order: 1;
    width: auto;
  }
`;
const Img = styled.img`
  display: block;
  margin: auto;
  max-width: 120px;
  width: 100%;
  height: auto;
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.white}; ;
`;

const ScrollTable = styled.div`
  overflow-x: auto;
  width: 100%;
  /* We're adding this in OverflowScrolling.css
	 * because the property gets removed from the
	 * styled component on test */
  /* -webkit-overflow-scrolling: touch; */
`;

const HistoryTable = styled(Table)`
  white-space: nowrap;

  td {
    padding: 10px;
  }
`;

const ElementHeader = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  line-height: 1;
`;

const ElementMoney = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.base};
  line-height: 1.5;
`;

const ElementInfo = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: ${({ theme }) => theme.borders[0]};
  border-radius: ${({ theme }) => theme.radii[2]};
`;

const ElementInfoItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-bottom: ${({ theme }) => theme.borders[0]};

  :last-child {
    border: 0;
  }
`;

const ElementInfoHeading = styled.h3`
  margin: 0;
  padding: 10px;
  width: 50%;
  font-size: 14px;
  line-height: 1;
  border-right: ${({ theme }) => theme.borders[0]};
`;

const ElementInfoValue = styled.div`
  padding: 10px;
  width: 50%;
  font-size: 14px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.yellow};
`;

const TableHeading = styled.h3`
  padding: ${({ theme }) => theme.space[2]};
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  border-top-left-radius: ${({ theme }) => theme.radii[2]};
  border-top-right-radius: ${({ theme }) => theme.radii[2]};
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  font-family: ${({ theme }) => theme.fonts.base};
  text-transform: none;
  font-size: 16px;
`;

const TablesWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

const TableWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(50% - 1rem);
  }
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  min-height: 108px;
  border-bottom-left-radius: ${({ theme }) => theme.radii[2]};
  border-bottom-right-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.blueDarker3};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 138px;
  }
`;

const TdBold = styled.td`
  font-weight: bold;
  height: 35px;
`;

interface IElementFixtureFPL extends IElementFixture {
  difficulty: number;
}

interface IOwnProps {
  element: IElement;
}

interface IPropsFromDispatch {
  addToWatched: (elementCode: number) => void;
  closeDialog: () => void;
  proposeElement: (elementId: number) => boolean;
  removeFromWatched: (elementCode: number) => void;
}

interface IPropsFromState {
  currentEvent: IEvent | null;
  elementTypesById: IElementTypesById;
  fixtures: Array<IElementFixtureFPL | IElementFixtureBlank>;
  nextEvent: IEvent | null;
  player: IPlayer | null;
  seasonHistory: IElementSeasonHistory[];
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
  watched: number[];
  history: IElementHistory[];
  historyTotals: Record<string, number | string>;
}

type Props = IOwnProps & IPropsFromDispatch & IPropsFromState & WithTranslation;

class ElementDialog extends React.Component<Props> {
  public added = false;
  public componentWillUnmount() {
    this.props.closeDialog();
  }

  public addElement = (elementId: number) => {
    this.props.proposeElement(elementId);
    this.props.closeDialog();
  };

  public renderWatchlistButton() {
    const { addToWatched, element, player, removeFromWatched, t, watched } =
      this.props;
    if (!player || !player.entry) {
      return null;
    }
    const inWatched = watched.indexOf(element.code) > -1;
    if (inWatched) {
      return this.added ? (
        <p>
          {t(
            "elementDialog.watchlist.view",
            "View your watchlist on the transfers page"
          )}
        </p>
      ) : (
        <Button
          onClick={() => {
            removeFromWatched(element.code);
          }}
          width={1}
        >
          {t("elementDialog.watchlist.remove", "Remove from Watchlist")}
        </Button>
      );
    }
    return (
      <Button
        onClick={() => {
          this.added = true;
          addToWatched(element.code);
        }}
        width={1}
      >
        {t("elementDialog.watchlist.add", "Add to Watchlist")}
      </Button>
    );
  }

  public render() {
    const {
      closeDialog,
      element,
      elementTypesById,
      fixtures,
      history,
      historyTotals,
      i18n,
      seasonHistory,
      stats,
      t,
      teamsById: teams,
    } = this.props;

    // Only get the last three in the history
    const lastThreeHistory = history.slice(-3);
    const firstThreeFixtures = fixtures.slice(0, 3);
    // Element info
    const elementType = elementTypesById[element.element_type];
    const elementFullname = `${element.first_name} ${element.second_name}`;
    const elementCost = `${integerToMoney(element.now_cost, 10)}Mkr`;

    return (
      <Dialog closeDialog={closeDialog}>
        <Dialog.Header closeDialog={closeDialog}>
          <Box py={2} px={3} bg="white">
            <ElementHeader>
              <DialogHeading>{elementFullname}</DialogHeading>
              <ElementMoney>{elementCost}</ElementMoney>
            </ElementHeader>
          </Box>

          <Box p={2} bg="#0e204d">
            <Button
              width={1}
              variant="secondary"
              onClick={() => this.addElement(element.id)}
            >
              {t("elementDialog.addPlayerCTA", "Add player")}
            </Button>
          </Box>
        </Dialog.Header>
        <Dialog.Body isPadded={false}>
          {element.news && (
            <Box m={2}>
              <Alert type="error">{element.news}</Alert>
            </Box>
          )}

          <Tabs>
            <TabPanel
              label={t("elementDialog.tabs.summary.heading", "Summary")}
              link="Summary"
            >
              <Summary>
                <Card>
                  <PlayerWrapper>
                    <Img
                      src={`https://allsvenskan.se/wp-content/uploads/2024/03/${element.code}.png`}
                      alt={t(
                        "elementDialog.tabs.summary.playerImageAlt",
                        "Player image for {{name}}",
                        { name: elementFullname }
                      )}
                      role="presentation"
                      onError={(e) => {
                        e.currentTarget.src = `${getElementShirtPath(
                          element.element_type,
                          teams[element.team].code,
                          "standard"
                        )}-110.png`;
                      }}
                    />
                  </PlayerWrapper>
                  <BadgeWrapper>
                    <Img
                      src={`${process.env.PUBLIC_URL}/img/badges/badge_${element.team_code}.png`}
                      alt={t(
                        "elementDialog.tabs.summary.teamBadgeAlt",
                        "Football club badge for {{name}}",
                        { name: teams[element.team].name }
                      )}
                      role="presentation"
                      onError={(e) => {
                        e.currentTarget.src = `${getElementShirtPath(
                          element.element_type,
                          teams[element.team].code,
                          "standard"
                        )}-110.png`;
                      }}
                    />
                  </BadgeWrapper>
                  <CardInfo>
                    <ElementInfo>
                      <ElementInfoItem>
                        <ElementInfoHeading>
                          {t("elementDialog.tabs.summary.position", "Position")}
                          :
                        </ElementInfoHeading>
                        <ElementInfoValue>
                          {elementType.singular_name}
                        </ElementInfoValue>
                      </ElementInfoItem>
                      <ElementInfoItem>
                        <ElementInfoHeading>
                          {t("elementDialog.tabs.summary.value", "Value")}:
                        </ElementInfoHeading>
                        <ElementInfoValue>{elementCost}</ElementInfoValue>
                      </ElementInfoItem>
                      <ElementInfoItem>
                        <ElementInfoHeading>
                          {t(
                            "elementDialog.tabs.summary.selectedBy",
                            "Selected by"
                          )}
                          :
                        </ElementInfoHeading>
                        <ElementInfoValue>
                          {element.selected_by_percent}%
                        </ElementInfoValue>
                      </ElementInfoItem>
                      <ElementInfoItem>
                        <ElementInfoHeading>
                          {t(
                            "elementDialog.tabs.summary.totalScore",
                            "Total score"
                          )}
                          :
                        </ElementInfoHeading>
                        <ElementInfoValue>
                          {element.total_points}
                        </ElementInfoValue>
                      </ElementInfoItem>
                    </ElementInfo>
                  </CardInfo>
                </Card>

                <TablesWrapper>
                  <TableWrapper>
                    <Panel $isFullHeight={true}>
                      <TableHeading>
                        {t(
                          "elementDialog.tabs.summary.recentMatches.title",
                          "Recent matches"
                        )}
                      </TableHeading>
                      {lastThreeHistory.length === 0 ? (
                        <NoData>
                          {t(
                            "elementDialog.tabs.summary.recentMatches.noData",
                            "No recent matches"
                          )}
                        </NoData>
                      ) : (
                        <Table>
                          <thead>
                            <tr>
                              <th>
                                <Tooltip
                                  content={t(
                                    "elementDialog.tabs.summary.recentMatches.roundTooltip",
                                    "Round"
                                  )}
                                >
                                  <TooltipLabel>
                                    {t(
                                      "elementDialog.tabs.summary.recentMatches.roundLabel",
                                      "RD"
                                    )}
                                  </TooltipLabel>
                                </Tooltip>
                              </th>
                              <th>
                                {t(
                                  "elementDialog.tabs.summary.recentMatches.opponent",
                                  "Opponent"
                                )}
                              </th>
                              <th>
                                {t(
                                  "elementDialog.tabs.summary.recentMatches.points",
                                  "Points"
                                )}
                              </th>
                              <th>
                                <VisuallyHidden>
                                  {t(
                                    "elementDialog.tabs.summary.recentMatches.win-loss-draw",
                                    "Result"
                                  )}
                                </VisuallyHidden>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lastThreeHistory.map((h: IElementHistory) => {
                              return (
                                <tr key={h.fixture}>
                                  <TdBold>{h.round}</TdBold>

                                  <TdBold>
                                    <BadgeScoreFlex>
                                      <EiwBadgeWrapper>
                                        <Badge
                                          team={teams[h.opponent_team]}
                                          isPresentation={true}
                                          sizes="25px"
                                        />
                                      </EiwBadgeWrapper>
                                      {teams[h.opponent_team].name} (
                                      {h.was_home
                                        ? t(
                                            "elementDialog.tabs.history.homeShort",
                                            "H"
                                          )
                                        : t(
                                            "elementDialog.tabs.history.awayShort",
                                            "A"
                                          )}
                                      ){" "}
                                      {h.team_h_score !== null &&
                                        `${h.team_h_score} - ${h.team_a_score}`}
                                    </BadgeScoreFlex>
                                  </TdBold>

                                  <TdBold>{h.total_points}</TdBold>
                                  <TdBold>
                                    {h.team_h_score === h.team_a_score ? (
                                      <DrawPrefix>
                                        {t(
                                          "elementDialog.tabs.history.draw",
                                          "D"
                                        )}
                                      </DrawPrefix>
                                    ) : h.team_h_score > h.team_a_score &&
                                      h.was_home ? (
                                      <WinPrefix>
                                        {t(
                                          "elementDialog.tabs.history.win",
                                          "W"
                                        )}
                                      </WinPrefix>
                                    ) : h.team_a_score > h.team_h_score &&
                                      !h.was_home ? (
                                      <WinPrefix>
                                        {t(
                                          "elementDialog.tabs.history.win",
                                          "W"
                                        )}
                                      </WinPrefix>
                                    ) : (
                                      <LosePrefix>
                                        {t(
                                          "elementDialog.tabs.history.loss",
                                          "L"
                                        )}
                                      </LosePrefix>
                                    )}
                                  </TdBold>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                    </Panel>
                  </TableWrapper>

                  <TableWrapper>
                    <Panel $isFullHeight={true}>
                      <TableHeading>
                        {t(
                          "elementDialog.tabs.summary.upcoming.title",
                          "Upcoming"
                        )}
                      </TableHeading>
                      {firstThreeFixtures.length === 0 ? (
                        <NoData>
                          {t(
                            "elementDialog.tabs.summary.upcoming.noData",
                            "No upcoming matches"
                          )}
                        </NoData>
                      ) : (
                        <Table>
                          <thead>
                            <tr>
                              <th>
                                <Tooltip
                                  content={t(
                                    "elementDialog.tabs.summary.upcoming.roundTooltip",
                                    "Round"
                                  )}
                                >
                                  <TooltipLabel>
                                    {t(
                                      "elementDialog.tabs.summary.upcoming.roundLabel",
                                      "RD"
                                    )}
                                  </TooltipLabel>
                                </Tooltip>
                              </th>
                              <th>
                                {t(
                                  "elementDialog.tabs.summary.upcoming.opponent",
                                  "Opponent"
                                )}
                              </th>
                              <th>
                                {t(
                                  "elementDialog.tabs.summary.upcoming.date",
                                  "Date"
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {firstThreeFixtures.map((fixture) =>
                              fixture.code ? (
                                <tr key={fixture.code}>
                                  <TdBold>{fixture.event}</TdBold>

                                  <TdBold>
                                    <BadgeScoreFlex>
                                      <FixtureForElement
                                        showLongName={true}
                                        showBadge={true}
                                        element={element}
                                        fixture={fixture}
                                        teamsById={teams}
                                      />
                                    </BadgeScoreFlex>
                                  </TdBold>

                                  <TdBold>
                                    {fixture.kickoff_time ? (
                                      <time dateTime={fixture.kickoff_time}>
                                        {formatRawAsLocalI18n(
                                          fixture.kickoff_time,
                                          dateLocales[i18n.language],
                                          "dd/MM/yy"
                                        )}
                                      </time>
                                    ) : (
                                      <Tooltip
                                        content={t(
                                          "elementDialog.tabs.summary.upcoming.tbcLong",
                                          "Date To Be Confirmed"
                                        )}
                                      >
                                        <span>
                                          <TooltipLabel>
                                            {t(
                                              "elementDialog.tabs.summary.upcoming.tbcShort",
                                              "TBC"
                                            )}
                                          </TooltipLabel>
                                        </span>
                                      </Tooltip>
                                    )}
                                  </TdBold>
                                </tr>
                              ) : (
                                <tr key={fixture.event!}>
                                  <TdBold>{fixture.event}</TdBold>
                                  <TdBold>
                                    {t(
                                      "elementDialog.tabs.summary.upcoming.none",
                                      "None"
                                    )}
                                  </TdBold>
                                  <TdBold />
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      )}
                    </Panel>
                  </TableWrapper>
                </TablesWrapper>
              </Summary>
            </TabPanel>

            <TabPanel
              label={t("elementDialog.tabs.history.heading", "History")}
              link="History"
            >
              <Box p={3}>
                <SubHeading>
                  {t("elementDialog.tabs.history.subHeading", "This Season")}
                </SubHeading>
              </Box>
              <Box>
                {history.length ? (
                  <ScrollTable className="ism-overflow-scroll">
                    <HistoryTable>
                      <thead>
                        <tr>
                          <th>
                            {t("elementDialog.tabs.history.roundShort", "RD")}
                          </th>
                          <th>
                            {t(
                              "elementDialog.tabs.history.opponentShort",
                              "OPP"
                            )}
                          </th>
                          <th>
                            {t("elementDialog.tabs.history.pointsShort", "PTS")}
                          </th>
                          {stats.map((s) => (
                            <th key={s.name}>
                              <Tooltip content={s.label}>
                                <TooltipLabel>
                                  {s.label
                                    .split(" ")
                                    .map((w) => w[0])
                                    .join("")
                                    .toUpperCase()}
                                </TooltipLabel>
                              </Tooltip>
                            </th>
                          ))}
                          <th>
                            {t(
                              "elementDialog.tabs.history.netTransfersShort",
                              "NT"
                            )}
                          </th>
                          <th>
                            {t(
                              "elementDialog.tabs.history.selectedByShort",
                              "SB"
                            )}
                          </th>
                          <th>kr</th>
                        </tr>
                      </thead>
                      <tbody>
                        {history.map((h) => (
                          <tr key={h.fixture}>
                            <td>{h.round}</td>
                            <td>
                              {teams[h.opponent_team].name} (
                              {h.was_home
                                ? t("elementDialog.tabs.history.homeShort", "H")
                                : t(
                                    "elementDialog.tabs.history.awayShort",
                                    "A"
                                  )}
                              ){" "}
                              {h.team_h_score !== null &&
                                `${h.team_h_score} - ${h.team_a_score}`}
                            </td>
                            <td>{h.total_points}</td>
                            {stats.map((s) => (
                              <td key={s.name}>{h[s.name]}</td>
                            ))}
                            <td>{h.transfers_balance}</td>
                            <td>{h.selected}</td>
                            <td>kr{integerToMoney(h.value, 10)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={2}>
                            {t("elementDialog.tabs.history.totals", "Totals")}
                          </td>
                          <td>{historyTotals.total_points}</td>
                          {stats.map((s) => (
                            <td key={s.name}>{historyTotals[s.name]}</td>
                          ))}
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </HistoryTable>
                  </ScrollTable>
                ) : (
                  <Box px={3}>
                    <p>
                      {t(
                        "elementDialog.tabs.history.noData",
                        "Data will appear here once the season is underway."
                      )}
                    </p>
                  </Box>
                )}
              </Box>

              {seasonHistory && seasonHistory.length > 0 && (
                <>
                  <Box p={3}>
                    <SubHeading>
                      {t(
                        "elementDialog.tabs.history.previousSeasons",
                        "Previous Seasons"
                      )}
                    </SubHeading>
                  </Box>
                  <Box>
                    <ScrollTable>
                      <HistoryTable>
                        <thead>
                          <tr>
                            <th>
                              {t("elementDialog.tabs.history.season", "Season")}
                            </th>
                            <th>
                              <Tooltip
                                content={t(
                                  "elementDialog.tabs.history.points",
                                  "Points"
                                )}
                              >
                                <TooltipLabel>TP</TooltipLabel>
                              </Tooltip>
                            </th>
                            {stats.map((s) => (
                              <th key={s.name}>
                                <Tooltip content={s.label}>
                                  <TooltipLabel>
                                    {s.label
                                      .split(" ")
                                      .map((w) => w[0])
                                      .join("")
                                      .toUpperCase()}
                                  </TooltipLabel>
                                </Tooltip>
                              </th>
                            ))}
                            <th>
                              <abbr
                                title={t(
                                  "elementDialog.tabs.history.priceAtStart",
                                  "Price at start of season"
                                )}
                              >
                                krS
                              </abbr>
                            </th>
                            <th>
                              <abbr
                                title={t(
                                  "elementDialog.tabs.history.priceAtEnd",
                                  "Price at end of season"
                                )}
                              >
                                krE
                              </abbr>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {seasonHistory.map((h) => (
                            <tr key={h.season_name}>
                              <td>{h.season_name}</td>
                              <td>{h.total_points}</td>
                              {stats.map((s) => (
                                <td key={s.name}>{h[s.name]}</td>
                              ))}
                              <td>kr{integerToMoney(h.start_cost, 10)}</td>
                              <td>kr{integerToMoney(h.end_cost, 10)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </HistoryTable>
                    </ScrollTable>
                  </Box>
                </>
              )}
            </TabPanel>

            <TabPanel
              label={t("elementDialog.tabs.schedule.heading", "Schedule")}
              link="Schedule"
            >
              <Box p={3}>
                <SubHeading>
                  {t("elementDialog.tabs.schedule.subHeading", "This Season")}
                </SubHeading>
              </Box>
              <Box>
                <Table>
                  <thead>
                    <tr>
                      <th>{t("elementDialog.tabs.schedule.date", "Date")}</th>
                      <th>
                        {t("elementDialog.tabs.schedule.roundShort", "RD")}
                      </th>
                      <th>
                        {t("elementDialog.tabs.schedule.opponent", "Opponent")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fixtures.map((fixture) =>
                      fixture.code ? (
                        <tr key={fixture.code}>
                          <td>
                            {fixture.kickoff_time ? (
                              <time dateTime={fixture.kickoff_time}>
                                {formatRawAsLocalI18n(
                                  fixture.kickoff_time,
                                  dateLocales[i18n.language]
                                )}
                              </time>
                            ) : (
                              <Tooltip
                                content={t(
                                  "elementDialog.tabs.schedule.tbcLong",
                                  "Date To Be Confirmed"
                                )}
                              >
                                <span>
                                  <TooltipLabel>
                                    {t(
                                      "elementDialog.tabs.schedule.tbcShort",
                                      "TBC"
                                    )}
                                  </TooltipLabel>
                                </span>
                              </Tooltip>
                            )}
                          </td>
                          <td>{fixture.event}</td>

                          <td>
                            <FixtureForElement
                              element={element}
                              fixture={fixture}
                              teamsById={teams}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr key={fixture.event!}>
                          <td>&nbsp;</td>
                          <td>{fixture.event}</td>
                          <td>
                            {t("elementDialog.tabs.schedule.none", "None")}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </Box>
            </TabPanel>
          </Tabs>

          <Box mx={2} my={3}>
            {this.renderWatchlistButton()}
          </Box>
        </Dialog.Body>
      </Dialog>
    );
  }
}

export { ElementDialog as ElementDialogTest };

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => ({
  currentEvent: getCurrentEvent(state),
  elementTypesById: getElementTypesById(state),
  fixtures: getFixturesWithBlanks(state, ownProps.element.id) as Array<
    IElementFixtureFPL | IElementFixtureBlank
  >,
  history: getHistory(state, ownProps.element.id),
  historyTotals: getHistoryTotals(state, ownProps.element.id),
  nextEvent: getNextEvent(state),
  player: getPlayerData(state),
  seasonHistory: getSeasonHistory(state, ownProps.element.id).slice().reverse(),
  stats: getElementStats(state),
  teamsById: getTeamsById(state),
  watched: getWatched(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  addToWatched: (elementCode) => dispatch(addToWatchlist(elementCode)),
  closeDialog: () => dispatch(elementDialogHide()),
  proposeElement: (elementId) => dispatch(proposeElement(elementId)),
  removeFromWatched: (elementCode) =>
    dispatch(removeFromWatchlist(elementCode)),
});

const ConnectedDialog = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ElementDialog)
);

/* tslint:disable:max-classes-per-file */
interface IContainerProps {
  element: IElement | undefined;
}

class ElementDialogContainer extends React.Component<IContainerProps> {
  public render() {
    const element = this.props.element;
    return element ? <ConnectedDialog element={element} /> : null;
  }
}

export default connect((state: RootState): IContainerProps => {
  const elementId = getElementDialog(state);
  return {
    element: getElement(state, elementId),
  };
})(ElementDialogContainer);
