import styled from "styled-components/macro";

const Bench = styled.div`
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.greyLight} 50%,
    ${({ theme }) => theme.colors.grey} 50%
  );

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    border-bottom-left-radius: ${({ theme }) => theme.radii[2]};
    border-bottom-right-radius: ${({ theme }) => theme.radii[2]};
  }
`;

export default Bench;
