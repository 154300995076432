import { em } from "polished";
import styled, { css } from "styled-components/macro";

interface IProps {
  size?: string;
}

export const ScrollTable = styled.div`
  th {
    :first-of-type {
      border-top-left-radius: ${({ theme }) => theme.radii[2]};
    }

    :last-of-type {
      border-top-right-radius: ${({ theme }) => theme.radii[2]};
    }
  }

  td {
    padding: 30px 0;
  }

  @media (max-width: ${em("480px")}) {
    overflow-x: auto;

    table {
      width: 48rem;
    }
  }
`;

export const THeadRadius = styled.div`
  th {
    :first-of-type {
      border-top-left-radius: ${({ theme }) => theme.radii[2]};
    }

    :last-of-type {
      border-top-right-radius: ${({ theme }) => theme.radii[2]};
    }
  }
`;

const Table = styled.table<IProps>`
  width: 100%;
  border-collapse: collapse;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: bold;

  th {
    padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
    font-weight: bold;

    ${({ size }) =>
      size === "lg" &&
      css`
        padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
      `}
  }

  td {
    padding: ${({ theme }) => `${theme.space[2]} ${theme.space[2]}`};

    ${({ size }) =>
      size === "lg" &&
      css`
        padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
      `}
  }

  th {
    outline: ${({ theme }) => theme.borders[3]};
    text-align: left;
    color: white;
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  tr {
    background-color: ${({ theme }) => theme.colors.blueDarker4};

    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.colors.blueDarker3};
    }
  }

  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: ${({ theme }) => theme.radii[2]};
  }

  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: ${({ theme }) => theme.radii[2]};
  }
`;

export default Table;
