import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import unibetx1 from "../img/logos/unibet@1x.png";
import unibetx2 from "../img/logos/unibet@2x.png";
import mgx1 from "../img/logos/mg@1x.png";
import mgx2 from "../img/logos/mg@2x.png";
import LogoFooter from "../img/logo_footer.svg";

// const UnibetTracker =
//   "https://track.adform.net/adfserve/?bn=36844312;1x1inv=1;srctype=3;ord=[timestamp]";

const StyledFooter = styled.footer``;

const Container = styled.div`
  max-width: 1000px;
  min-height: 0;
  min-width: 0;
  margin: 0 auto;
`;

const Img = styled.img`
  width: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
  max-height: 50px;
  max-width: 100%;
`;

const SponsorRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SponsorCol = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ededed;
  padding: 45px 10px;
  text-align: center;
`;

const SponsorRowThirds = styled(SponsorRow)`
  ${SponsorCol} {
    flex-basis: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      flex-basis: calc(100% / 3);
    }
  }
`;

const SponsorType = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.space[2]};
  text-transform: uppercase;
  font-weight: 100;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.black};
`;

const CopyWrite = styled.div`
  font-weight: bold;
  font-size: 11px;
  color: #ffd600;
`;

const Info = styled.div`
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
`;

const Copy = styled.div``;

const Links = styled.div`
  margin: ${({ theme }) => theme.space[2]} 0;

  a {
    color: white;
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;
    margin-right: ${({ theme }) => theme.space[2]};

    &:hover {
      text-decoration: underline;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: 0;
    width: 60%;
    text-align: left;
  }
`;

const Logo = styled.div`
  width: 112px;
  height: 24px;
  margin: 0 auto;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: 0;
  }
`;

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledFooter>
      <Box mt={4} bg="white">
        <Container>
          <SponsorRowThirds>
            <SponsorCol>
              <div>
                {/* <a
                  href="http://track.adform.net/C/?bn=36844312"
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                <Img
                  srcSet={`${unibetx1}, ${unibetx2} 2x`}
                  src={unibetx2}
                  alt={t(
                    "footer.unibetAlt",
                    "Unibet main partner logo for Allsvenskan"
                  )}
                />
                {/* </a> */}
                <SponsorType>
                  {t("footer.unibetHeading", "Main Partner")}
                </SponsorType>
                {/* <Img src={UnibetTracker} alt="" /> */}
              </div>
            </SponsorCol>
            <SponsorCol>
              <div>
                <a
                  href="https://www.mgmotor.eu/sv-SE?&utm_source=fantasy&utm_medium=banner&utm_campaign=fantasy_league"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    srcSet={`${mgx1}, ${mgx2} 2x`}
                    src={mgx2}
                    alt={t(
                      "footer.mgAlt",
                      "MG official partner logo for Allsvenskan"
                    )}
                  />
                </a>
                <SponsorType>
                  {t("footer.mgHeading", "Official Partner")}
                </SponsorType>
              </div>
            </SponsorCol>
          </SponsorRowThirds>
        </Container>
      </Box>
      <Box bg="#0E204D" p={3}>
        <Container>
          <Info>
            <Copy>
              <CopyWrite>© ALLSVENSKAN 2024</CopyWrite>
            </Copy>
            <Links>
              <a href="http://www.svenskelitfotboll.se/allmanna-villkor/">
                Allmänna villkor
              </a>
              <a href="http://www.svenskelitfotboll.se/integritetspolicy/">
                INTEGRITETSPOLICY
              </a>
              <a href="http://www.svenskelitfotboll.se/svensk-elitfotboll-och-gdpr/">
                GDPR
              </a>
            </Links>
            <Logo>
              <img
                src={LogoFooter}
                alt="Allsvenskan white a logo with yellow circle within"
              />
            </Logo>
          </Info>
        </Container>
      </Box>
    </StyledFooter>
  );
};

export default Footer;
