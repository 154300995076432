// my-theme.ts
import { em } from "polished";
import { DefaultTheme } from "styled-components";

const black = "#242424";
const blue = "#12326e";
const darkPink = "#e90052";
const fantasy = "#00ff87";
const greyLight = "#dadada";
const lightBlue = "#174b93";
const lighterBlue = "#658ac7";
const primary = "#12326e";
const yellow = "#c2a94b";
const whiteGrey = "#f0f0f0";

const theme: DefaultTheme = {
  borders: [
    `1px solid ${lighterBlue}`,
    `1px solid ${yellow}`,
    `1px solid ${whiteGrey}`,
    `1px solid ${lightBlue}`,
  ],
  breakpoints: [
    em("400px"),
    em("610px"),
    em("700px"),
    em("900px"),
    em("1024px"),
    em("1220px"),
  ],
  colors: {
    black: black,
    blue: "#12326e",
    blueDark: "#005b6d",
    lighterBlue: "#658ac7",
    darkPink,
    difficulties: {
      1: {
        bg: "#257d5a",
        color: "white",
      },
      2: {
        bg: "#00ff86",
        color: "black",
      },
      3: {
        bg: "#ebebe4",
        color: "black",
      },
      4: {
        bg: "#ff005a",
        color: "white",
      },
      5: {
        bg: "#861d46",
        color: "white",
      },
    },
    elementStatus: {
      0: {
        bg: "#c0020d",
        color: "white",
      },
      25: {
        bg: "#d44401",
        color: "white",
      },
      50: {
        bg: "#ffab1b",
        color: black,
      },
      75: {
        bg: "#ffe65b",
        color: black,
      },
    },
    elementTypes: {
      1: {
        bg: yellow,
        color: primary,
      },
      2: {
        bg: fantasy,
        color: primary,
      },
      3: {
        bg: blue,
        color: primary,
      },
      4: {
        bg: darkPink,
        color: "white",
      },
    },
    error: "#db1c77",
    fantasy,
    grey: "#b2b2b2",
    greyDark: "#505050",
    greyLight,
    pink: "#ff2882",
    primary,
    greyDarker1: "#e5e5e5",
    greyDarker2: "#d1d1d1",
    greyDarker3: "#6e6e6e",
    blueDarker1: "#174b93",
    blueDarker2: "#004986",
    blueDarker3: "#0e204d",
    blueDarker4: "#0f2659",
    secondary: lightBlue,
    social: {
      facebook: {
        bg: "#3b5998",
        color: "white",
      },
      twitter: {
        bg: "#00aced",
        color: "white",
      },
      google: {
        bg: "white",
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    success: "#51f5c4",
    tertiary: "#d2eff9",
    text: black,
    white: "#fff",
    whiteBlue: "#f2f9fa",
    whiteGrey,
    yellow,
  },
  fontSizes: ["1.2rem", "1.3rem", "1.4rem", "1.6rem", "1.8rem"],
  fonts: {
    base: "Gilroy-Medium, Arial, sans-serif;",
    impact: "BBStrataPro, Arial, sans-serif;",
  },
  radii: ["2px", "5px", "10px"],
  space: ["0", "0.5rem", "1rem", "1.5rem", "2rem"],
  zIndex: {
    clientMenu: 7000,
    dialog: 9000,
    menu: 6000,
    overlay: 8000,
    select: {
      select: 50,
      wrap: 40,
    },
  },
};

export default theme;
