import * as React from "react";
import styled, { css } from "styled-components/macro";

const StyledAlert = styled.div<IStyleProps>`
  padding: 0.75rem 1.25rem;
  text-align: ${(props) => props.textAlign};
  border: 1px solid transparent;
  border-radius: ${({ noRadius, theme }) => (noRadius ? 0 : theme.radii[1])};

  ${(props) =>
    props.type === "error" &&
    css`
      background-color: ${props.theme.colors.error};
      color: ${props.theme.colors.white};
    `}

  ${(props) =>
    props.type === "success" &&
    css`
      background-color: ${props.theme.colors.success};
      color: ${props.theme.colors.black};
    `}

  ${(props) =>
    props.type === "warning" &&
    css`
      background-color: ${props.theme.colors.error};
      color: ${props.theme.colors.white};
    `}

  ${(props) =>
    props.type === "info" &&
    css`
      background-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.white};
    `}
`;

export const AlertItem = styled.p`
  margin-top: ${(props) => props.theme.space[1]};
  margin-bottom: ${(props) => props.theme.space[1]};
  color: currentColor;
`;

export const AlertWrap = styled.div`
  margin: ${(props) => props.theme.space[2]};
`;

export const AlertGroup = styled.div`
  margin-top: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[3]};
`;

interface IStyleProps {
  type: "error" | "success" | "warning" | "info";
  textAlign?: "left" | "center";
  noRadius?: boolean;
}

interface IProps extends IStyleProps {
  children: React.ReactNode;
}

const Alert: React.FC<IProps> = ({
  children,
  textAlign = "center",
  type,
  noRadius = false,
}) => (
  <StyledAlert type={type} textAlign={textAlign} noRadius={noRadius}>
    {children}
  </StyledAlert>
);

export default Alert;
