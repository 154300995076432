import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementStatsByIdentifier } from "../../core/store/element-stats/reducers";
import { IElementStat } from "../../core/store/element-stats/types";
import {
  getElementsById,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import {
  IElementExplain,
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import { getFixturesForEventById } from "../../core/store/fixtures/reducers";
import { IFixture } from "../../core/store/fixtures/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import Button from "../Button";
import Dialog, { DialogButtonItem } from "../Dialog";
import DialogHeading from "../DialogHeading";
import Table from "../Table";

const ExplainHeading = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  text-align: center;
`;

const ExplainScore = styled.span`
  margin: 0 ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[1]};
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
`;

interface IOwnProps {
  closeDialog: () => void;
  elementId: number;
  eventId: number;
}

interface IPropsFromState {
  elementsById: IElementsById;
  elementsDataById: IElementsEventDataById | null;
  fixturesById: Record<string, IFixture> | null;
  statsByIdentifier: Record<string, IElementStat>;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

class ElementExplainDialog extends React.Component<Props> {
  public showElementDialog = (elementId: number) => {
    this.props.showElementDialog(elementId);
    this.props.closeDialog();
  };

  public renderExplainFixture(explain: IElementExplain) {
    const {
      elementId,
      elementsDataById,
      fixturesById,
      statsByIdentifier,
      teamsById,
      t,
    } = this.props;
    const elementData = elementsDataById![elementId];
    const fixture = fixturesById && fixturesById[explain.fixture];
    if (!elementData || !fixture) {
      return null;
    }
    if (!fixture.started) {
      return null;
    }
    return (
      <>
        <Box mb={4}>
          <ExplainHeading>
            {teamsById[fixture.team_h].short_name}
            <ExplainScore>
              {fixture.team_h_score}-{fixture.team_a_score}
            </ExplainScore>
            {teamsById[fixture.team_a].short_name}
          </ExplainHeading>
        </Box>
        <Box mb={4}>
          <Table>
            <thead>
              <tr>
                <th>{t("elementExplainDialog.stat", "Statistic")}</th>
                <th>{t("elementExplainDialog.value", "Value")}</th>
                <th>{t("elementExplainDialog.points", "Points")}</th>
              </tr>
            </thead>
            <tbody>
              {explain.stats.map((stat) => (
                <tr key={stat.identifier}>
                  <td>{statsByIdentifier[stat.identifier].label}</td>
                  <td>{stat.value}</td>
                  <td>{stat.points}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </>
    );
  }

  public render() {
    const { closeDialog, elementsById, elementsDataById, fixturesById, t } =
      this.props;
    const element = elementsById[this.props.elementId];
    if (!element || !fixturesById || !elementsDataById) {
      return null;
    }
    return (
      <Dialog closeDialog={closeDialog}>
        <Dialog.Header closeDialog={closeDialog}>
          <Box py={2} px={3} bg="white">
            <DialogHeading>{`${element.first_name} ${element.second_name}`}</DialogHeading>
          </Box>
        </Dialog.Header>

        <Dialog.Body>
          {elementsDataById[element.id].explain.map((e) => (
            <React.Fragment key={e.fixture}>
              {this.renderExplainFixture(e)}
            </React.Fragment>
          ))}
          <div>
            <DialogButtonItem>
              <Button
                onClick={() => this.showElementDialog(element.id)}
                width={1}
              >
                {t("elementExplainDialog.info", "View Information")}
              </Button>
            </DialogButtonItem>
          </div>
        </Dialog.Body>
      </Dialog>
    );
  }
}

export { ElementExplainDialog as ElementExplainDialogTest };

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => {
  return {
    elementsById: getElementsById(state),
    elementsDataById: getElementsEventDataById(state, ownProps.eventId),
    fixturesById: getFixturesForEventById(state, ownProps.eventId),
    statsByIdentifier: getElementStatsByIdentifier(state),
    teamsById: getTeamsById(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ElementExplainDialog)
);
