import Parser from "rss-parser";

function filterOnSSL(items: Parser.Item[]) {
  return items.filter(function (item: Parser.Item) {
    if (item && item.link && item.enclosure && item.enclosure.url) {
      return (
        item.link.indexOf("https") !== -1 &&
        item.enclosure.url.indexOf("https") !== -1
      );
    }
    // this to stop eslint flagging a warning, methods like map, filter and reduce
    // always expect something to be returned
    return [];
  });
}

export default filterOnSSL;
