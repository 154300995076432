import * as React from "react";
import { Button as Base } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";

export interface IButtonStyledProps {
  disabled?: boolean;
  fullwidth?: string; // "true"
  variant?: string; //"primary" | "secondary" | "link";
  size?: string; // "sm" || "md" || "lg"
  hasicon: string; // "true" || ""
}

const btnVariations = {
  baseBtn: css`
    display: inline-block;
    padding: 1.5rem 1.2rem;
    border: 0;
    background-color: transparent;
    font-family: ${({ theme }) => theme.fonts.base};
    font-size: ${({ theme }) => theme.fontSizes[3]};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 1;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 250ms linear;
    text-decoration: none;
    font-weight: bold;
  `,
  primary: css`
    background-color: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.black};
  `,
  secondary: css`
    border: ${({ theme }) => theme.borders[0]};
    background-color: ${({ theme }) => theme.colors.blueDarker1};
    color: ${({ theme }) => theme.colors.white};
  `,
  tertiary: css`
    background-color: #00aac2;
    color: ${({ theme }) => theme.colors.white};
  `,
  link: css`
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  `,
  fullwidth: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  `,
  xs: css`
    font-size: 1.2rem;
    padding: 0.8rem;
  `,
  sm: css`
    font-size: 1.4rem;
    padding: 1rem;
  `,
  lg: css`
    padding: 1.6rem;
  `,
};

export const StyledBtn = styled(Base)<IButtonStyledProps>`
  ${btnVariations.baseBtn};
  ${({ variant }) => variant === "primary" && btnVariations.primary};
  ${({ variant }) => variant === "secondary" && btnVariations.secondary};
  ${({ variant }) => variant === "tertiary" && btnVariations.tertiary};
  ${({ variant }) => variant === "link" && btnVariations.link};
  ${({ fullwidth }) => fullwidth === "true" && btnVariations.fullwidth}
  ${({ size }) => size === "xs" && btnVariations.xs}
  ${({ size }) => size === "sm" && btnVariations.sm}
  ${({ size }) => size === "lg" && btnVariations.lg}
  ${({ fullwidth, hasicon }) =>
    fullwidth === "true" &&
    hasicon === "true" &&
    css`
      justify-content: space-between;
    `};
  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.greyLight};
      color: ${theme.colors.grey};
      cursor: auto;

      &:hover {
        /* background-color: ${theme.colors.grey};
        color: ${theme.colors.greyDarker3}; */
        cursor: not-allowed;
        pointer-events: all !important;
      }
    `}
`;

interface IProps {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  width?: number;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  ref: React.RefObject<HTMLButtonElement>;
  children: React.ReactNode;
  disabled?: boolean;
  variant?: string;
  size?: string;
  // Need to clean this part up
  to?: string;
  href?: string;
  as?: any;
  tabIndex?: number;
  role?: string;
  target?: string;
  newTab?: boolean;
  rel?: string;
}

type Props = IProps;

const Button = React.forwardRef(
  (
    {
      as,
      width = 0,
      children,
      disabled = false,
      variant = "primary",
      type = "button",
      startIcon: startIconProp,
      endIcon: endIconProp,
      newTab = false,
      ...props
    }: Props,
    ref?: React.Ref<HTMLButtonElement>
  ) => {
    const commonProps = {
      ref,
      disabled,
      variant,
      fullwidth: width === 1 ? "true" : "undefined",
      hasicon: !(startIconProp || endIconProp) ? "" : "true",
    };

    const startIcon = startIconProp && (
      <span style={{ marginRight: 8 }}>{startIconProp}</span>
    );

    const endIcon = endIconProp && (
      <span style={{ marginLeft: 8 }}>{endIconProp}</span>
    );

    // If it has a "href" prop we assume it's wants to be an anchor
    // Or it could be a Link component but you can use the prop.as for this as={Link}
    if (props.href || props.to) {
      if (disabled) {
        props.tabIndex = -1;
        props.role = "button";
      }

      if (newTab) {
        props.target = "_blank";
        props.rel = "noopener noreferrer";
      }

      return (
        <StyledBtn as={as || "a"} {...commonProps} {...props}>
          {startIcon}
          {children}
          {endIcon}
        </StyledBtn>
      );
    }

    return (
      <StyledBtn type={type} {...commonProps} {...props}>
        {startIcon}
        {children}
        {endIcon}
      </StyledBtn>
    );
  }
);

export default Button;
