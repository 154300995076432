import React from "react";
import { IFixture } from "../core/store/fixtures/types";
import { IElement, IElementFixture } from "../core/store/elements/types";
import { ITeamsById } from "../core/store/teams/types";
import Badge from "./Badge";
import styled from "styled-components/macro";

interface IProps {
  showLongName?: boolean;
  showBadge?: boolean;
  fixture: IFixture | IElementFixture;
  element: IElement;
  teamsById: ITeamsById;
}

const BadgeWrap = styled.div`
  max-width: 25px;
  max-height: 25px;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[1]}
    ${theme.space[1]} 0`};
`;

const FixtureForElement: React.FC<IProps> = ({
  showBadge = false,
  showLongName = false,
  fixture,
  element,
  teamsById,
}) => {
  const isHome = element.team === fixture.team_h;
  return isHome ? (
    <>
      {showBadge && (
        <BadgeWrap>
          <Badge sizes={"25px"} team={teamsById[fixture.team_a]}></Badge>
        </BadgeWrap>
      )}
      {showLongName
        ? `${teamsById[fixture.team_a].name} (H)`
        : `${teamsById[fixture.team_a].short_name} (H)`}
    </>
  ) : (
    <>
      {showBadge && (
        <BadgeWrap>
          <Badge sizes={"25px"} team={teamsById[fixture.team_h]}></Badge>
        </BadgeWrap>
      )}
      {showLongName
        ? `${teamsById[fixture.team_h].name} (B)`
        : `${teamsById[fixture.team_h].short_name} (B)`}
    </>
  );
};

export default FixtureForElement;

interface IMultiProps {
  delimiter?: React.ReactNode;
  fixtures: IFixture[];
  element: IElement;
  teamsById: ITeamsById;
}

export const FixturesForElement: React.FC<IMultiProps> = ({
  delimiter = ", ",
  fixtures,
  element,
  teamsById,
}) => {
  const fragments = fixtures.map((f) => (
    <FixtureForElement
      element={element}
      fixture={f}
      teamsById={teamsById}
      key={f.id}
    />
  ));
  return fragments.length
    ? fragments.reduce((result, item) => (
        <>
          {result}
          {delimiter}
          {item}
        </>
      ))
    : null;
};
