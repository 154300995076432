import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
// import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  dreamTeamAsPickLight,
  getEventDreamTeam,
  getEventDreamTeamFormation,
} from "../../core/store/dream-teams/reducers";
import { fetchEventDreamTeam } from "../../core/store/dream-teams/thunks";
import {
  getElementsById,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import { fetchEventLive } from "../../core/store/elements/thunks";
import { IPickLight } from "../../core/store/entries/types";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { getFixturesForEventById } from "../../core/store/fixtures/reducers";
import { fetchFixtures } from "../../core/store/fixtures/thunks";
import { getTeamsById } from "../../core/store/teams/reducers";
import ElementShirt from "../ElementShirt";
import SubHeading from "../SubHeading";
import ElementExplainDialog from "../team/ElementExplainDialog";
import valueForPlayerElement from "../team/valueForPlayedElement";
import { UnstyledButton } from "../Utils";
import StatusFormation from "./StatusFormation";

const StyledDreamTeam = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    border-radius: ${({ theme }) => theme.radii[2]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
  }
`;

const PitchWrap = styled.div`
  padding-top: ${({ theme }) => theme.space[4]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 66.66%;
  }
`;

const TopPlayerWrap = styled.div`
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 33.33%;
  }
`;

const TopScorer = styled.div`
  text-align: center;
`;

const TopScorerHeading = styled.h4`
  margin: 0 0 ${({ theme }) => theme.space[3]};
  color: white;
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 2rem;
  text-transform: uppercase;
`;

const TopScorerCard = styled.div`
  display: inline-block;
`;

const TopScorerShirt = styled.div`
  padding: ${({ theme }) => `${theme.space[2]} 3rem`};
  background-color: white;
  border-top-left-radius: ${({ theme }) => theme.radii[2]};
  border-top-right-radius: ${({ theme }) => theme.radii[2]};
`;

const TopScorerData = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: bold;
`;

const TopScorerName = styled.div`
  padding: ${({ theme }) => theme.space[1]};
  background-color: ${({ theme }) => theme.colors.blueDarker3};
  color: ${({ theme }) => theme.colors.white};
`;

const TopScorerPts = styled.div`
  padding: ${({ theme }) => theme.space[1]};
  background-color: ${({ theme }) => theme.colors.yellow};
  color: ${({ theme }) => theme.colors.black};
`;

const TopScorerTeam = styled.div`
  padding: ${({ theme }) => theme.space[1]};
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  border-bottom-left-radius: ${({ theme }) => theme.radii[2]};
  border-bottom-right-radius: ${({ theme }) => theme.radii[2]};
  background-color: white;
  color: ${({ theme }) => theme.colors.black};
`;

const StatusDreamTeam: React.FC = () => {
  const { t } = useTranslation();
  const [elementForMenu, setElementForMenu] = useState(0);
  const now = useSelector(getCurrentEvent);
  const data = useSelector((state: RootState) =>
    now ? getEventDreamTeam(state, now.id) : null
  );
  const formation = useSelector((state: RootState) =>
    now ? getEventDreamTeamFormation(state, now.id) : null
  );
  const elementsById = useSelector(getElementsById);
  const elementsDataById = useSelector((state: RootState) =>
    now ? getElementsEventDataById(state, now.id) : null
  );
  const fixturesById = useSelector((state: RootState) =>
    now ? getFixturesForEventById(state, now.id) : null
  );
  const teamsById = useSelector(getTeamsById);

  const reduxDispatch = useDispatch<ThunkDispatch>();
  useEffect(() => {
    if (now) {
      const eventId = now.id;
      reduxDispatch(fetchEventDreamTeam(eventId));
      reduxDispatch(fetchEventLive(eventId));
      reduxDispatch(fetchFixtures(eventId));
    }
  }, [elementForMenu, now, reduxDispatch]);

  if (!data || !formation || !now) {
    return null;
  }
  const picks = dreamTeamAsPickLight(data.team);
  const topPlayer = elementsById[data.top_player.id];

  const handleShowMenuForElement = (element: number) => {
    setElementForMenu(element);
  };

  const handleHideMenuForElement = () => {
    setElementForMenu(0);
  };

  // Create a new function on each render as data could have changed and
  // need to ensure a render of connected subcomponents
  const renderPickValue = now
    ? valueForPlayerElement({
        elementsById,
        elementsDataById,
        fixturesById,
        teamsById,
      })
    : (pick: IPickLight) => {
        const elementData = data.team.filter((e) => e.element === pick.element);
        return elementData.length ? elementData[0].points : null;
      };

  return (
    <div>
      <Box mb={2} px={2}>
        <SubHeading>
          {t("statusDreamTeam.heading", "{{ eventName }} Dream Team", {
            eventName: now.name,
          })}
        </SubHeading>
      </Box>
      <StyledDreamTeam>
        <PitchWrap>
          <StatusFormation
            chipName={""}
            formation={formation}
            picks={picks}
            renderDreamTeam={() => null}
            renderElementMenu={handleShowMenuForElement}
            renderPickValue={renderPickValue}
          />
        </PitchWrap>
        <TopPlayerWrap>
          <TopScorer>
            <TopScorerHeading>
              {t("statusDreamTeam.topScorer", "{{ eventName }} Top Scorer", {
                eventName: now.name,
              })}
            </TopScorerHeading>
            <TopScorerCard>
              <UnstyledButton
                onClick={() => handleShowMenuForElement(topPlayer.id)}
              >
                <TopScorerShirt>
                  <ElementShirt
                    elementId={topPlayer.id}
                    sizes="(min-width: 1024px) 130px, 106px"
                  />
                </TopScorerShirt>
                <TopScorerData>
                  <TopScorerName>{topPlayer.web_name}</TopScorerName>
                  <TopScorerPts>{data.top_player.points}</TopScorerPts>
                  <TopScorerTeam>
                    {teamsById[topPlayer.team].short_name}
                  </TopScorerTeam>
                </TopScorerData>
              </UnstyledButton>
            </TopScorerCard>
          </TopScorer>
        </TopPlayerWrap>
      </StyledDreamTeam>

      {elementForMenu ? (
        <ElementExplainDialog
          elementId={elementForMenu}
          eventId={now.id}
          closeDialog={handleHideMenuForElement}
        />
      ) : null}
    </div>
  );
};

export default StatusDreamTeam;
