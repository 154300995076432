import * as React from "react";
import styled, { css } from "styled-components/macro";

const Item = styled.li`
  flex: 1;
`;

export const TabLink = styled.a<IStyleProps>`
  display: block;
  margin: 0.2rem;
  padding: 0.7rem ${({ theme }) => theme.space[2]};
  border-radius: 2px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${({ theme }) => theme.colors.yellow};
      border-radius: ${({ theme }) => theme.radii[0]};
    `}
`;

interface IStyleProps {
  selected: boolean;
}

interface IProps {
  key: number;
  index: number;
  label: React.ReactNode;
  link: string;
  onClick: (index: number) => void;
}

type Props = IStyleProps & IProps;

class Tab extends React.Component<Props> {
  public onClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.props.onClick(this.props.index);
  };

  public render() {
    const { label, link, selected } = this.props;
    return (
      <Item>
        <TabLink href={`#${link}`} onClick={this.onClick} selected={selected}>
          {label}
        </TabLink>
      </Item>
    );
  }
}

export default Tab;
